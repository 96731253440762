import React from 'react';

import { buildEmberRouteRedirect, getEventLogger, PrerequisiteRedirect } from 'z-frontend-app-bootstrap';
import { useSwitches } from 'z-frontend-network';

import { BUNDLE_EXPIRED, PREREQUISITE_REDIRECT_BODY } from '../../redirects/prerequisiteRedirectBody';
import ModalWithQuery from './ModalWithQuery';
import RedirectConfirmationModal from './RedirectConfirmationModal';

type RedirectModalProps = {
  redirects: PrerequisiteRedirect[];
};

const RedirectModal: React.FC<RedirectModalProps> = props => {
  const { redirects } = props;
  const [redirect] = redirects;
  const body =
    redirect && PREREQUISITE_REDIRECT_BODY[redirect.group] && PREREQUISITE_REDIRECT_BODY[redirect.group].content;

  const switches = useSwitches();

  if (!body) {
    getEventLogger().logError(new Error(`redirect_modal - key DNE :${redirect.group}`));
    return null;
  }
  const modalBody = redirect.objectType === 'CO' ? body.CO : body.EE;
  const contentRequiredQuery = modalBody && modalBody.query;

  if (contentRequiredQuery) {
    return <ModalWithQuery modalConfig={modalBody} prerequisiteRedirect={redirect} />;
  }

  const clcDisableSelfServeUpgrade = switches?.clc_disable_self_serve_upgrade;

  const hideUpgradeLinks = redirect.group === BUNDLE_EXPIRED && clcDisableSelfServeUpgrade;

  const content = modalBody.body(null, clcDisableSelfServeUpgrade);
  const showFooter = modalBody?.showFooter;
  const title = modalBody?.title;
  const redirectEmberRoute = modalBody?.redirectEmberRoute;
  const redirectUrl = hideUpgradeLinks ? null : modalBody?.redirectUrl;
  const footerText = showFooter ? (hideUpgradeLinks ? 'Close' : modalBody?.footerButtonText) : '';

  const onSubmit = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else if (redirectEmberRoute) {
      window.location.href = buildEmberRouteRedirect({
        to: redirectEmberRoute,
      });
    }
  };

  return (
    <RedirectConfirmationModal
      modalContent={content}
      modalTitle={title}
      submitActionText={footerText}
      onSubmit={onSubmit}
    />
  );
};

export default RedirectModal;
