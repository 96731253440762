import React, { Component, FunctionComponent } from 'react';
import { get } from 'lodash';

import { Box, Flex } from 'zbase';
import { space } from 'z-frontend-theme/utils';

import { styled, Render } from 'z-frontend-theme';
import { useContractorLiteCompanyFeature, DashboardApp } from 'z-frontend-layout';
import { Link } from 'z-frontend-elements';

import AddWorkerButton from './Add';
import SeeAllWorkersButton from './SeeAll';
import WorkerAvatar, { WorkerAvatarSkeleton } from './WorkerAvatar';
import SectionHeading from '../SectionHeading';
import { AllEmployee } from '../../../schema/schemaTypes';
import { getAppTileBreakdown, AppTileCard } from '../apps/app-utils';
import { employeeComparator as fallbackSort, getDashboardUrl, Worker } from './workerUtil';

const defaultNumberOfWorkersVisible = 16;

function isValidEmployee(employee: AllEmployee): boolean {
  return Boolean(employee.preferredOrFirstName && employee.last_name);
}

function employeeToWorker(employee: AllEmployee): Worker {
  return {
    id: employee.id,
    preferredOrFirstName: employee.preferredOrFirstName,
    lastName: employee.last_name,
    photoUrl: employee.photoUrl,
    hireDate: employee.hireDate,
    status: employee.status,
    allStatus: employee.allStatus,
    reportToEmployeeId: get(employee, 'reportToEmployee.id'),
    employmentType: employee.employmentType,
  };
}

function isAppEnabled(apps: AppTileCard[], appUniqueId: string) {
  const app = apps.find(app => app.uniqueId === appUniqueId);
  if (app) {
    return app.status === 'enabled';
  }
  return false;
}

export type WorkerListProps = {
  workers: AllEmployee[];
  teammates: AllEmployee[];
  currentlyLoggedInWorker: AllEmployee;
  role: 'ADM' | 'EMP';
  apps: DashboardApp[];
  canViewAdmin?: boolean;
  readOnly?: boolean;
  loading?: boolean;
};

const WorkerAvatarGrid = styled(Box)`
  /* simple fallback for IE11 to make it usable, at least */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media (min-width: ${props => props.theme.breakpoints[1]}em) {
    flex-wrap: wrap;
    @supports (display: grid) {
      display: grid;
      grid-template-rows: 1fr auto; /* 2 rows, second one only if necessary */
      grid-template-columns: repeat(auto-fill, minmax(75px, 1fr)); /* set columns automatically based on space */
      grid-auto-rows: 0; /* set height to 0 for implicit (later) grid rows */
      overflow-y: hidden; /* hide grid items over 2 rows */
      min-width: 340px; /* prevent weirdness at really small widths */
      column-gap: ${space(2)};
    }
  }
`;

const FinalGridCellPositioner = styled(Box)`
  @supports (display: grid) {
    grid-row-start: 2;
    grid-column-end: -1;
  }
`;

export const WorkerSectionHeading: FunctionComponent<{ role: WorkerListProps['role'] }> = props => {
  const { role } = props;
  const isContractorPaymentsCompany = useContractorLiteCompanyFeature();
  const workersCopy = isContractorPaymentsCompany ? 'Contractors' : 'Workers';
  if (!role) {
    // hold the space
    return <SectionHeading mt={[3, null, 5]}>&nbsp;</SectionHeading>;
  }
  return <SectionHeading mt={[3, null, 5]}>{role === 'ADM' ? workersCopy : 'My Team'}</SectionHeading>;
};

class WorkerList extends Component<WorkerListProps> {
  static defaultProps = {
    canViewAdmin: false,
    readOnly: false,
  };

  render() {
    const { role, currentlyLoggedInWorker, workers, teammates, apps, canViewAdmin, readOnly, loading } = this.props;
    if (loading || !role) {
      return (
        <>
          <WorkerSectionHeading role={role} />
          <WorkerAvatarGrid>
            {Array.from(Array(12).keys()).map(value => (
              <WorkerAvatarSkeleton key={value} data-testid="WorkerAvatarSkeleton" />
            ))}
          </WorkerAvatarGrid>
        </>
      );
    }

    const appTiles = getAppTileBreakdown(apps).dashboardCards;
    const isOrgChartEnabled = isAppEnabled(appTiles, '1.com.zenefits.EmployeeDirectory');
    const showEmployeesList = isOrgChartEnabled || canViewAdmin;
    if (!showEmployeesList) {
      return null;
    }

    const validWorkers = workers
      .filter(isValidEmployee)
      .map(employeeToWorker)
      .sort(fallbackSort);
    const validTeammates = teammates.filter(isValidEmployee).map(employeeToWorker);
    const workersToDisplay = role === 'ADM' ? validWorkers : validTeammates;

    const isManagerHiringEnabled =
      (currentlyLoggedInWorker.isManager && isAppEnabled(appTiles, '1.com.zenefits.HiringForManagers')) ||
      isAppEnabled(appTiles, '1.com.zenefits.ZenefitsForManagers');
    const isNormalAdmin = role === 'ADM' && !readOnly;
    const showHireButton = isNormalAdmin || isManagerHiringEnabled;

    let maxWorkersVisible = defaultNumberOfWorkersVisible;
    if (showHireButton) {
      maxWorkersVisible -= 1;
    }
    const showSeeAll = workersToDisplay.length > maxWorkersVisible;
    if (showSeeAll) {
      maxWorkersVisible -= 1;
    }

    const avatars = workersToDisplay
      .slice(0, maxWorkersVisible)
      .map(({ id, preferredOrFirstName, lastName, photoUrl, employmentType }) => (
        <WorkerAvatar
          key={id}
          className="js-walkme-worker-avatar"
          data-testid="WorkerAvatar"
          id={id}
          firstName={preferredOrFirstName}
          lastName={lastName}
          photoUrl={photoUrl}
          contractor={employmentType === 'CO'}
        />
      ));

    const dashboardUrl = getDashboardUrl(window.location.href);
    const directoryUrl = `${dashboardUrl}employeedirectory`;
    return (
      <>
        <Flex justify="space-between">
          <WorkerSectionHeading role={role} />
          <Render forBreakpoints={[true, true, false]}>
            <Link href={directoryUrl} mt={[3, null, 5]} fontStyle="paragraphs.m">
              View All
            </Link>
          </Render>
        </Flex>

        <WorkerAvatarGrid>
          {showHireButton && <AddWorkerButton />}
          {avatars}
          {showSeeAll && (
            <FinalGridCellPositioner>
              <SeeAllWorkersButton />
            </FinalGridCellPositioner>
          )}
        </WorkerAvatarGrid>
      </>
    );
  }
}

export default WorkerList;
