export const JAZZ_HR_RECRUITING_PROD = '159.com.jazzhr.recruit';
export const OKTA_ZAPP_UNIQUE_ID = '197.com.okta';

export const RECRUITING_APPS_UNIQUE_ID_LIST = [
  JAZZ_HR_RECRUITING_PROD,
  '159.com.jazzhr.recruit.dev',
  '159.com.jazzhr.recruit.staging',
  '159.com.jazzhr.env71',
  '159.com.jazzhr.env94',
];

export const INTEGRATIONS_TO_OVERRIDE_EMBER_ROUTE = ['339.com.F01KIntegrations'];

// JazzHR v1 integration. App tile title is JazzHR, not Recruiting.
const jazzHRAppUniqueId = '159.com.jazzhr';

export const jazzHRAndRecruitingAppUniqueIds = [...RECRUITING_APPS_UNIQUE_ID_LIST, jazzHRAppUniqueId];

export const PRE_INSTALL_APPS_UNIQUE_ID_LIST = RECRUITING_APPS_UNIQUE_ID_LIST;

export const RECRUITING_MARKETING_URL = 'https://zenefits.com/hr/recruiting';

export const RECRUITING_MARKETING_APP_TILE = {
  status: 'notEnrolled',
  uniqueId: 'zenefits_recruiting_redirect',
  name: 'Recruiting',
  appUrl: RECRUITING_MARKETING_URL,
  iconUrl: 'https://zenefits-static-ui-assets-us-west-2.s3.amazonaws.com/static/platform/img/logos/recruiting.png',
  role: 'ADM',
  isExternal: true,
};

export const IOM_DISABLED_APPS = [
  '1.com.zenefits.BusinessInsuranceAdmin',
  '282.com.zywave.hrlibrary',
  '1.com.zenefits.UnicardCommuterEmployee',
  '1.com.zenefits.UnicardCommuterAdmin',
  '1.com.zenefits.ComplianceAdmin',
  '1.com.zenefits.Influitive',
  '1.com.zenefits.PeopleAnalyticsAdmin',
  '1.com.zenefits.Employees',
  '1.com.zenefits.PayrollAdmin',
  '1.com.zenefits.Paystubs',
  '1.com.zenefits.TimeAttendanceEmployee',
  '1.com.zenefits.TimeAttendanceAdmin',
  '1.com.zenefits.PtoAdmin',
  '1.com.zenefits.PtoEmployee',
  '1.com.zenefits.Hiring',
  '1.com.zenefits.EmployeeDirectory',
  '1.com.zenefits.ZReferralProgram',
  '1.com.zenefits.WellbeingEmployee',
  '1.com.zenefits.WellbeingAdmin',
  '1.com.zenefits.TalentEmployee',
  '1.com.zenefits.TalentAdmin',
  '1.com.zenefits.HrAdvisor',
  '1.com.zenefits.PayrollIntegrations',
];
export const CONTRACTOR_ENABLED_APPS = [
  '1.com.zenefits.Employees',
  '1.com.zenefits.DocumentsAdmin',
  '1.com.zenefits.DocumentsEmployee',
  '1.com.zenefits.CompanyAdmin',
  '1.com.zenefits.PersonalInfo',
  '1.com.zenefits.Paystubs',
  '1.com.zenefits.Hiring',
  '1.com.zenefits.HiringForManagers',
  '1.com.zenefits.PayrollAdmin',
];

export const BENEFITS_ADMIN = 'is_benefits_admin';
export const FULL_COMPANY_ADMIN = 'is_full_company_admin';
export const MAIN_ADMIN = 'is_main_admin';
export const TRINET_CONNECTORS_TERMS_URL =
  'https://zenefits.s3.amazonaws.com/VirtualTraining/TriNet_Connectors_Terms_FAA-18Oct2019';
export const INTERNAL_DOMAINS = ['zenefits.com', 'trinet.com'];
