define('component-library/components/payroll/pay-types/pay-types-component', ['exports', 'ember', 'component-library/data-table/column', 'component-library/data-table/cells/empty-header-cell', 'component-library/components/payroll/pay-types/link-action-table-cell', 'component-library/constants/z-payroll/z-payroll-pay-types', 'component-library/utils/globals', 'component-library/constants/z-payroll/z-payroll-earning-categories', 'component-library/data-table/cells/tooltip-table-cell'], function (exports, _ember, _componentLibraryDataTableColumn, _componentLibraryDataTableCellsEmptyHeaderCell, _componentLibraryComponentsPayrollPayTypesLinkActionTableCell, _componentLibraryConstantsZPayrollZPayrollPayTypes, _componentLibraryUtilsGlobals, _componentLibraryConstantsZPayrollZPayrollEarningCategories, _componentLibraryDataTableCellsTooltipTableCell) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/payroll/pay-types/pay-types-component',
		showEarningModal: false,
		showDeductionModal: false,
		showContributionModal: false,
		existingPayItem: null,

		zPayrollEnums: _ember['default'].A(),
		accountingOrganizations: _ember['default'].A(),
		accountingAccounts: _ember['default'].A(),
		deductionTypes: _ember['default'].A(),
		contributionTypes: _ember['default'].A(),
		earningTypes: _ember['default'].A(),
		payTypesPendingProgress: null,
		zpCompany: null,
		currentYear: (0, _componentLibraryUtilsGlobals.moment)().get('year'),
		transitionToNextRoute: function transitionToNextRoute() {
			return;
		},

		hasPriorPayrollNotStartInNextYear: _ember['default'].computed('zpCompany.hasPriorPayroll', 'zpCompany.startInNextYear', function () {
			return this.get('zpCompany.hasPriorPayroll') && !this.get('zpCompany.startInNextYear');
		}),

		benefitCategories: _ember['default'].computed('zPayrollEnums', function () {
			var zPayrollEnums = this.get('zPayrollEnums');
			var categories = [];
			if (this.get('showContributionModal')) {
				categories.push.apply(categories, _toConsumableArray(zPayrollEnums.filterBy('type', 'ContributionCategory')));
			} else if (this.get('showDeductionModal')) {
				categories.push.apply(categories, _toConsumableArray(zPayrollEnums.filterBy('type', 'DeductionCategory')));
			} else {
				categories.push.apply(categories, _toConsumableArray(zPayrollEnums.filterBy('type', 'BenefitCatgory')));
			}
			var unsupportedCategories = [];
			if (this.get('showContributionModal')) {
				unsupportedCategories.push.apply(unsupportedCategories, _toConsumableArray(_componentLibraryConstantsZPayrollZPayrollPayTypes.UnsupportedContributionCategories));
			} else if (this.get('showDeductionModal')) {
				unsupportedCategories.push.apply(unsupportedCategories, _toConsumableArray(_componentLibraryConstantsZPayrollZPayrollPayTypes.UnsupportedDeductionCategories));
			} else {
				unsupportedCategories.push.apply(unsupportedCategories, _toConsumableArray(_componentLibraryConstantsZPayrollZPayrollPayTypes.UnsupportedBenefitCategories));
			}
			// Only companies on the pyp_403b_catchup_override switch can use this category
			if (!this.get('switches.pyp_403b_catchup_override')) {
				unsupportedCategories.push('403b_catch_up_override');
			}
			if (!this.get('switches.pyp_allow_general_deferred_compensation')) {
				unsupportedCategories.push('general_deferred_compensation');
			}
			if (!this.get('switches.pyp_allow_pension')) {
				unsupportedCategories.push('pension_contribution');
				unsupportedCategories.push('pension_deduction');
			}
			return categories.reject(function (category) {
				return unsupportedCategories.includes(category.get('value'));
			}).sortBy('verboseName');
		}),

		earningCategories: _ember['default'].computed('zPayrollEnums', function () {
			var _this = this;

			return this.get('zPayrollEnums').filterBy('type', 'EarningCategory').sortBy('verboseName').filter(function (earning) {
				var unsupportedEarningCategories = _componentLibraryConstantsZPayrollZPayrollPayTypes.UnsupportedEarningCategories;

				if (!_this.get('switches.pyp_bypass_disabled_earning_types') || _this.get('isTrialCompany') || _this.get('isTrialSales')) {
					unsupportedEarningCategories.push(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.CASH_TIPS, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.NON_CASH_TIPS);
				}

				if (!_this.get('switches.pyp_third_party_sick_pay')) {
					unsupportedEarningCategories.push.apply(unsupportedEarningCategories, _toConsumableArray(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY_TYPES.THIRD_PARTY_SICK_PAY_TYPES));
				}

				// Only supported companies can use RSU Deferral earning category
				if (!_this.get('switches.pyp_allow_rsu_deferral')) {
					unsupportedEarningCategories.push(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.RSU_DEFERRAL);
				}

				// Only supported companies can use general imputed income category
				if (!_this.get('switches.pyp_allow_general_imputed_income')) {
					unsupportedEarningCategories.push(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.GENERAL_IMPUTED_INCOME);
				}

				if (!_this.get('switches.pyp_allow_credit_card_reported_tips')) {
					unsupportedEarningCategories.push(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.CREDIT_CARD_REPORTED_TIPS);
				}

				unsupportedEarningCategories.push.apply(unsupportedEarningCategories, _toConsumableArray(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY_TYPES.COVID19_TYPES));

				if (!_this.get('switches.custom_scorp_health_and_disability')) {
					unsupportedEarningCategories.push(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.SCORP_HEALTH_DISABILITY);
				}

				// Migrating R1 switch to R3 switch
				if (!_this.get('switches.loa_push_to_payroll_r3')) {
					unsupportedEarningCategories.push(_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY_TYPES.LEAVE_OF_ABSENCE);
				}

				return !unsupportedEarningCategories.includes(earning.get('value'));
			});
		}),

		formatCategoryName: function formatCategoryName(type, category) {
			var enumObject = this.get('zPayrollEnums').filterBy('type', type).findBy('value', category);
			return _ember['default'].get(enumObject, 'verboseName');
		},

		contributionTypesColumns: _ember['default'].computed('zPayrollEnums', function () {
			var _this2 = this;

			return [_componentLibraryDataTableColumn['default'].create({
				savedWidth: 290,
				headerCellName: 'Contribution Name',
				contentPath: 'name',
				canAutoResize: true
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 200,
				headerCellName: 'Contribution Category',
				contentPath: 'category',
				getCellContent: function getCellContent(row) {
					var category = row.get('category');
					return _this2.formatCategoryName('ContributionCategory', category);
				}
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 150,
				headerCellName: 'Rate Type',
				contentPath: 'contributionRateTypeName'
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 50,
				headerCellName: '',
				getCellContent: function getCellContent(row) {
					return _this2._getLockedReason(row);
				},
				headerCellViewClass: _componentLibraryDataTableCellsEmptyHeaderCell['default'],
				textAlign: 'u-textCenter',
				actionName: 'deleteRow',
				tableCellViewClass: _componentLibraryComponentsPayrollPayTypesLinkActionTableCell['default']
			})];
		}),

		deductionTypesColumns: _ember['default'].computed('zPayrollEnums', function () {
			var _this3 = this;

			return [_componentLibraryDataTableColumn['default'].create({
				savedWidth: 290,
				headerCellName: 'Deduction Name',
				contentPath: 'name',
				canAutoResize: true,
				tableCellViewClass: _componentLibraryDataTableCellsTooltipTableCell['default'],
				tooltipPath: 'toolTipCatchupMessage'
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 200,
				headerCellName: 'Deduction Category',
				contentPath: 'category',
				getCellContent: function getCellContent(row) {
					var category = row.get('category');
					return _this3.formatCategoryName('DeductionCategory', category);
				}
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 150,
				headerCellName: 'Rate Type',
				contentPath: 'deductionRateTypeName'
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 50,
				headerCellName: '',
				getCellContent: function getCellContent(row) {
					return _this3._getLockedReason(row);
				},
				headerCellViewClass: _componentLibraryDataTableCellsEmptyHeaderCell['default'],
				textAlign: 'u-textCenter',
				actionName: 'deleteRow',
				tableCellViewClass: _componentLibraryComponentsPayrollPayTypesLinkActionTableCell['default']
			})];
		}),

		earningTypesColumns: _ember['default'].computed('zPayrollEnums', function () {
			var _this4 = this;

			return [_componentLibraryDataTableColumn['default'].create({
				savedWidth: 290,
				headerCellName: 'Earnings Name',
				contentPath: 'name',
				canAutoResize: true
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 200,
				headerCellName: 'Earnings Category',
				contentPath: 'category',
				getCellContent: function getCellContent(row) {
					var category = row.get('category');
					return _this4.formatCategoryName('EarningCategory', category);
				}
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 150,
				headerCellName: 'Rate Type',
				contentPath: 'rateTypeName'
			}), _componentLibraryDataTableColumn['default'].create({
				savedWidth: 50,
				headerCellName: '',
				getCellContent: function getCellContent(row) {
					return _this4._getLockedReason(row);
				},
				headerCellViewClass: _componentLibraryDataTableCellsEmptyHeaderCell['default'],
				textAlign: 'u-textCenter',
				actionName: 'deleteRow',
				tableCellViewClass: _componentLibraryComponentsPayrollPayTypesLinkActionTableCell['default']
			})];
		}),

		_getLockedReason: function _getLockedReason(item) {
			if (item.get('isGenerated')) {
				var _name = item.get('name');
				var category = item.get('category');
				var isVariableComp = [_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.BONUS, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.COMMISSION, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.OWNERS_DRAW].includes(category) && _name && _name.includes('Variable Compensation - ');
				if (isVariableComp) {
					return null;
				}
				return 'This pay item is managed by Zenefits and cannot be edited.';
			} else if (item.get('shouldLock')) {
				return 'This pay item has already been used on an approved or paid pay run and cannot be edited. Choose "Add" above to create a new pay item.';
			}
			return null;
		},

		actions: {
			deleteRecord: function deleteRecord(record) {
				record.deleteRecord();
			},

			toggleModal: function toggleModal(property, payItem) {
				this.set('existingPayItem', payItem);
				this.set(property, !this.get(property));
			},

			finishPendingPayTypes: function finishPendingPayTypes() {
				var _this5 = this;

				var payTypesPendingProgress = this.get('payTypesPendingProgress');
				payTypesPendingProgress.set('state', 'COMPLETE');
				payTypesPendingProgress.save().then(function () {
					return _this5.transitionToNextRoute();
				});
			}
		}
	});
});