import React, { FunctionComponent } from 'react';

import { ButtonDropdown } from 'z-frontend-elements';
import { Box, Flex, Icon, TextInline } from 'zbase';
import { Avatar } from 'z-frontend-composites';
import { Hide, Render } from 'z-frontend-theme';
import { logout } from 'z-frontend-app-bootstrap';
import { checkPermission, usePermissions, SwitchChecker } from 'z-frontend-network';
import { useContractorLiteCompanyFeature } from 'z-frontend-app-bootstrap/src/createIntlProvider';

import { useCheckContractorLiteCompany } from '../../client-nav-content/utils';

export const isFullCompanyAdminPermission = 'is_full_company_admin';

type AvatarDropdownProps = {
  firstName: string;
  lastName: string;
  supportId?: string;
  photoUrl: string;
  showInbox: boolean;
  showMyAccounts: boolean;
  showAccountSettings: boolean;
  accountSettingsUrl?: string;
  dropdownItems: React.ReactNode;
  className?: string;
};

const AvatarDropdown: FunctionComponent<AvatarDropdownProps> = ({
  firstName,
  lastName,
  supportId,
  photoUrl,
  showInbox,
  showMyAccounts,
  showAccountSettings,
  dropdownItems,
  accountSettingsUrl,
}) => {
  const { permissions } = usePermissions();
  const isContractorLiteCompany = useCheckContractorLiteCompany();
  const showPlanInfoLink = !isContractorLiteCompany && checkPermission(permissions, isFullCompanyAdminPermission);
  const isContractorPaymentsCompany = useContractorLiteCompanyFeature();

  return (
    <ButtonDropdown
      target={
        <Flex align="center" style={{ cursor: 'pointer' }}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            photoUrl={photoUrl}
            s="small"
            tooltipBody=""
            aria-label="My account"
            className="js-walkme-top-nav-avatar"
          />
          <Hide forBreakpoints={[true]}>
            <Icon iconName="chevron-down" ml={2} />
          </Hide>
        </Flex>
      }
      popperPlacement="bottom-end"
      data-testid="openavatardropdown"
      // TODO: popper is too close to target
      // not quite right: moves left/right when bottom placed
      // popperModifiers={{ offset: { offset: '20%p' } }}
    >
      <Render forBreakpoints={[true]}>
        {showInbox && <ButtonDropdown.ItemLink href="/dashboard/#/inbox-view">Inbox</ButtonDropdown.ItemLink>}
      </Render>

      {showMyAccounts && <ButtonDropdown.ItemLink href="/companySelector">My Accounts</ButtonDropdown.ItemLink>}

      {!isContractorPaymentsCompany && showAccountSettings && (
        <ButtonDropdown.ItemLink href={accountSettingsUrl || '/dashboard/#/account-settings/settings'}>
          Account Settings
        </ButtonDropdown.ItemLink>
      )}

      {dropdownItems}

      {!isContractorPaymentsCompany && (
        <SwitchChecker switch="top_nav_referal_link">
          <ButtonDropdown.ItemLink
            href="https://www.zenefits.com/referral?utm_source=product&utm_content=dashboard_avatar_dropdown&utm_medium=organic&utm_campaign=customer_referral_2021-12"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="refer-and-earn"
          >
            Refer & Earn
          </ButtonDropdown.ItemLink>
        </SwitchChecker>
      )}

      {showPlanInfoLink && (
        <ButtonDropdown.ItemLink href="/dashboard/#/company-profile/plan-information" data-testid="plan-info-link">
          Manage Plan
        </ButtonDropdown.ItemLink>
      )}

      <ButtonDropdown.ItemButton
        onClick={() => {
          logout();
        }}
        data-testid="top-nav-log-out"
      >
        Log Out
      </ButtonDropdown.ItemButton>

      <Flex bg="grayscale.white" py={2} pl={3} pr={1} justify="flex-start">
        <Box fontStyle="paragraphs.s" color="grayscale.d" py={1}>
          Support ID: <TextInline color="text.dark">{supportId || 'n/a'}</TextInline>
        </Box>
      </Flex>
    </ButtonDropdown>
  );
};

export default AvatarDropdown;
