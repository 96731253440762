import gql from 'graphql-tag';

export const topNavQuery = gql`
  query TopNavBarQuery {
    dashboard {
      id
      isMTAUser
      isMTAPartnerUser
      isConsoleUser
      isSpoofing
      switches
      permission
      isTrialCompany
      isTrialSales
      canSeeDemoCenter
      trialHasFreeLimitedCompany
      companyTypeIsDemo
      isSelfServeTrial
      trialPromoCode
      user {
        id
        first_name
        last_name
        email
      }
      employee {
        id
        employeeNumber
        supportId
        first_name
        last_name
        photoUrl
      }
      partner {
        id
        companyId
      }
      company {
        id
        name
        payrollCompanyHasContractors
        prospectAccount {
          buyingExperienceToken
        }
      }
    }
    prerequisiteRedirect
  }
`;

export const topNavBarBannerQuery = gql`
  query TopNavBarBannerQuery {
    dashboard {
      id
      zAppInstallSubscriptions {
        id
        appInstall {
          id
          status
          app {
            id
            uniqueId
          }
        }
      }
    }
  }
`;

export const companyNameQuery = gql`
  query CompanyNameQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
    }
  }
`;
