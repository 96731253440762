define('component-library/components/payroll/pay-types/add-benefit-type-modal', ['exports', 'ember', 'component-library/components/promise-modal'], function (exports, _ember, _componentLibraryComponentsPromiseModal) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/payroll/pay-types/add-benefit-type-modal',
		selectedAccountingOrganization: null,
		zpCompany: null,
		isGenerated: false,
		shouldLock: false,
		isDeductionType: true,
		modalErrorText: null,
		eventLogger: _ember['default'].inject.service(),
		payTypesService: _ember['default'].inject.service('payroll/pay-types-service'),
		disableCategoryModification: _ember['default'].computed.or('model.id', 'isGenerated'),
		disabled: _ember['default'].computed.or('shouldLock', 'isGenerated'),
		showHelpCenterBanner: _ember['default'].computed.and('isDeductionType', 'helpCenterLink'),
		payTypeCategory: _ember['default'].computed.alias('model.category'),
		isNewBenefit: _ember['default'].computed.alias('model.isNew'),
		zPayrollEnums: _ember['default'].A(),

		helpCenterLinksForDeductions: {
			health_savings: "https://help.zenefits.com/Health_Savings_Account/Getting_Started_with_Health_Savings_Accounts_(HSAs)_in_Zenefits/08_Limits_on_2018_HSA_Contributions",
			health_savings_catch_up: "https://help.zenefits.com/Health_Savings_Account/Getting_Started_with_Health_Savings_Accounts_(HSAs)_in_Zenefits/08_Limits_on_2018_HSA_Contributions",
			health_savings_family: "https://help.zenefits.com/Health_Savings_Account/Getting_Started_with_Health_Savings_Accounts_(HSAs)_in_Zenefits/08_Limits_on_2018_HSA_Contributions",
			health_savings_family_catch_up: "https://help.zenefits.com/Health_Savings_Account/Getting_Started_with_Health_Savings_Accounts_(HSAs)_in_Zenefits/08_Limits_on_2018_HSA_Contributions",
			transit_benefits: "https://help.zenefits.com/Commuter_Benefits/Getting_Started_with_Commuter_Benefits_in_Zenefits/08-Contribution_Limits_for_Commuter_Benefits",
			parking_benefits: "https://help.zenefits.com/Commuter_Benefits/Getting_Started_with_Commuter_Benefits_in_Zenefits/08-Contribution_Limits_for_Commuter_Benefits"
		},

		init: function init() {
			this._super();
			var model = this.get('model');
			var typeClass = this.get('isDeductionType') ? window.App.ZPayrollCompanyDeductionType : window.App.ZPayrollCompanyContributionType;
			if (!model) {
				this.set('model', typeClass.createRecord({
					category: this.get('benefitCategories.firstObject.value'),
					zpCompany: this.get('zpCompany'),
					isGenerated: false,
					isActive: true
				}));
			}
		},

		deductionTypes: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		contributionTypes: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		accountingOrganizations: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		accountingAccounts: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		benefitCategories: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		filteredBenefitCategories: _ember['default'].computed('benefitCategories', 'disableCategoryModification', 'isDeductionType', 'zPayrollEnums', function () {
			var disableCategoryModification = this.get('disableCategoryModification');
			var isDeductionType = this.get('isDeductionType');
			var benefitCategories = this.get('benefitCategories');
			var unsupportedCategories = [];

			/*
   	If a system generated benefit type is opened in this modal, everything is read-only.
   		COVID-19 contribution types are system-generated and cannot be created from the UI.
   		The `z-select-field-basic` component will not show a value if it's not in the list of options.
   		In order to correctly show the category for read-only COVID-19 contribution types,
   		we must make sure NOT to exclude them here. But when creating or editing non-disabled
   		types, COVID-19 contribution types SHOULD be excluded.
   */
			if (!isDeductionType && !disableCategoryModification) {
				unsupportedCategories.push.apply(unsupportedCategories, _toConsumableArray(this.get('zPayrollEnums').filterBy('type', 'Covid19ContributionCategories').map(function (contributionType) {
					return contributionType.get('value');
				})));
			}

			return benefitCategories.reject(function (category) {
				return unsupportedCategories.includes(category.get('value'));
			});
		}),

		modalHeaderText: _ember['default'].computed('typeName', 'isNewBenefit', function () {
			return this.get('isNewBenefit') ? 'Add ' + this.get('typeName') + ' Type' : 'Edit ' + this.get('typeName') + ' Type';
		}),

		modalNameLabelText: _ember['default'].computed('typeName', function () {
			return this.get('typeName') + ' Name';
		}),

		modalPayPeriodLabelText: _ember['default'].computed('typeName', function () {
			return this.get('typeName') + ' Per Pay Period';
		}),

		modalCategoryLabelText: _ember['default'].computed('typeName', function () {
			return this.get('typeName') + ' Category';
		}),

		modalNameErrorText: null,

		helpCenterLink: _ember['default'].computed('payTypeCategory', function () {
			return this.get('helpCenterLinksForDeductions')[this.get('payTypeCategory')];
		}),

		typeName: _ember['default'].computed('isDeductionType', function () {
			return this.get('isDeductionType') ? 'Deduction' : 'Contribution';
		}),

		hasCompanyMax: _ember['default'].computed('payTypeCategory', 'isDeductionType', function () {
			var isContribution = !this.get('isDeductionType');
			var category = this.get('payTypeCategory');
			var categoriesWithCompanyMax = ['401k', '401k_catchup', 'fsa', 'fsa_dependent', 'health_savings', 'health_savings_post'];
			return isContribution && categoriesWithCompanyMax.indexOf(category) >= 0;
		}),

		isDeductionTypeCatchup: _ember['default'].computed('payTypeCategory', 'isDeductionType', function () {
			var isDeduction = this.get('isDeductionType');
			var category = this.get('payTypeCategory');
			var categoriesWithCatchupForSecure2Dot0 = ['401k_catchup', '403b_catchup', 'roth_401k_catchup', 'roth_403b_catchup', '457_catchup', 'simple_ira_catch_up', 'simple_401k_catchup'];
			return isDeduction && categoriesWithCatchupForSecure2Dot0.indexOf(category) >= 0;
		}),

		validAccountingAccounts: _ember['default'].computed('accountingAccounts.[]', 'selectedAccountingOrganization', function () {
			var accountingOrganization = this.get('selectedAccountingOrganization');
			return this.get('accountingAccounts').filterBy('accountingOrganization', accountingOrganization);
		}),

		selectedExpenseAccounts: _ember['default'].computed('model.accountingAccounts.[]', 'selectedAccountingOrganization', 'isDeductionType', function (key, value) {
			var accountingOrganization = this.get('selectedAccountingOrganization');
			var accounts = this.get('model.accountingAccounts');
			if (arguments.length === 1) {
				return accounts.findBy('accountingOrganization', accountingOrganization);
			} else if (value) {
				var accountsToRemove = accounts.filterBy('accountingOrganization', accountingOrganization);
				accounts.removeObjects(accountsToRemove);
				accounts.pushObject(value);
				return value;
			}
			return value;
		}),

		hasDuplicate: function hasDuplicate(existingObjects, modifiedObject) {
			return existingObjects.any(function (object) {
				return object.get('id') != modifiedObject.get('id') && object.get('name').trim() == modifiedObject.get('name').trim() && object.get('isActive');
			});
		},

		_clearErrors: function _clearErrors() {
			this.set('modalNameErrorText', null);
			this.set('modalErrorText', null);
		},

		actions: {
			saveBenefit: function saveBenefit() {
				var _this = this;

				this._clearErrors();

				var model = this.get('model');

				var nameValue = model.get('name');
				if (nameValue) {
					model.set('name', nameValue.trim());
				}

				if (!model.get('name')) {
					this.set('modalNameErrorText', 'Please specify a name.');
					return _ember['default'].RSVP.reject();
				}

				var existingObjects = this.get('deductionTypes');
				var message = "A deduction with this name already exists. Choose a different name and try again.";
				if (!this.get('isDeductionType')) {
					existingObjects = this.get('contributionTypes');
					message = "A contribution with this name already exists. Choose a different name and try again.";
				}

				if (this.hasDuplicate(existingObjects, model)) {
					this.set('modalNameErrorText', message);
					return _ember['default'].RSVP.reject();
				}

				return model.save().then(function () {
					existingObjects.reload();
					_this.attrs.closeModal();
				})['catch'](function (error) {
					_this.set('modalErrorText', 'Internal error: ' + JSON.parse(error.responseText).error_message);
				});
			},

			cancelBenefit: function cancelBenefit() {
				var model = this.get('model');
				if (this.get('isNewBenefit')) {
					model.deleteRecord();
				}
				this.attrs.closeModal();
			},

			deleteBenefit: function deleteBenefit(deleteButton) {
				var _this2 = this;

				this._clearErrors();

				var payType = this.get('model');
				var payTypeId = payType.get('id');
				var payTypesService = this.get('payTypesService');
				var isDeductionType = this.get('isDeductionType');
				var companyId = this.get('zpCompany.company.id');

				var deletePromise = isDeductionType ? payTypesService.deleteDeductionPayType(payTypeId, companyId) : payTypesService.deleteContributionPayType(payTypeId, companyId);

				deletePromise.then(function () {
					payType.set('isActive', null); //Delete in front-end
					_this2.attrs.closeModal();
				})['catch'](function (error) {
					var eventName = isDeductionType ? 'delete_deduction_pay_type_error' : 'delete_contribution_pay_type_error';

					_this2.get('eventLogger').log(eventName, {
						error: error.responseText,
						payTypeId: payTypeId
					});

					var message = 'Oops! Something went wrong. We are working on getting this fixed as soon as we can. Please try again.';
					_this2.set('modalErrorText', 'Internal error: ' + message);
				})['finally'](function () {
					deleteButton.stop();
					var existingObjects = isDeductionType ? _this2.get('deductionTypes') : _this2.get('contributionTypes');
					existingObjects.reload();
				});
			}
		}
	});
});