define("component-library/components/report-configuration-modal", ["exports", "ember", "component-library/components/promise-modal", "component-library/components/confirmation-modal", "component-library/components/notification-modal", "component-library/templates/components/report-configuration-modal/search-suggestion", "component-library/utils/globals", "component-library/constants/z-payroll/z-payroll-report-types", "component-library/data-table/column", "component-library/data-table/cells/empty-header-cell", "jquery"], function (exports, _ember, _componentLibraryComponentsPromiseModal, _componentLibraryComponentsConfirmationModal, _componentLibraryComponentsNotificationModal, _componentLibraryTemplatesComponentsReportConfigurationModalSearchSuggestion, _componentLibraryUtilsGlobals, _componentLibraryConstantsZPayrollZPayrollReportTypes, _componentLibraryDataTableColumn, _componentLibraryDataTableCellsEmptyHeaderCell, _jquery) {
	"use strict";

	var TAX_FILING_TYPE_MANUAL = "manual";
	var NEW_HIRE_REPORT_SELECT_ALL = "SELECT_ALL";
	var NEW_HIRE_REPORT_ALL_STATE = "OTHER_STATES";
	var NEW_HIRE_REPORT_AZ = "AZ_NEW_HIRE";
	var NEW_HIRE_REPORT_CA = "CA_NEW_HIRE";
	var NEW_HIRE_REPORT_DC = "DC_NEW_HIRE";
	var NEW_HIRE_REPORT_CO = "CO_NEW_HIRE";
	var NEW_HIRE_REPORT_FL = "FL_NEW_HIRE";
	var NEW_HIRE_REPORT_GA = "GA_NEW_HIRE";
	var NEW_HIRE_REPORT_MA = "MA_NEW_HIRE";
	var NEW_HIRE_REPORT_NJ = "NJ_NEW_HIRE";
	var NEW_HIRE_REPORT_NY = "NY_NEW_HIRE";
	var NEW_HIRE_REPORT_TX = "TX_NEW_HIRE";
	var NEW_HIRE_REPORT_UT = "UT_NEW_HIRE";
	var NEW_HIRE_REPORT_VA = "VA_NEW_HIRE";
	var NEW_HIRE_REPORT_WA = "WA_NEW_HIRE";
	var NEW_HIRE_REPORT_IL = "IL_NEW_HIRE";
	var NEW_HIRE_REPORT_MI = "MI_NEW_HIRE";
	var NEW_HIRE_REPORT_NV = "NV_NEW_HIRE";
	var NEW_HIRE_REPORT_DE = "DE_NEW_HIRE";
	var NEW_HIRE_REPORT_WY = "WY_NEW_HIRE";
	var NEW_HIRE_REPORT_CT = "CT_NEW_HIRE";
	var NEW_HIRE_REPORT_AL = "AL_NEW_HIRE";
	var NEW_HIRE_REPORT_PA = "PA_NEW_HIRE";
	var NEW_HIRE_REPORT_NM = "NM_NEW_HIRE";

	// todo update url
	var reportSearchCompaniesUrl = "/custom_api/v1/z_payroll/companies?q=%QUERY";

	exports["default"] = _componentLibraryComponentsPromiseModal["default"].extend({
		classNames: ["report-configuration-modal"],
		templateName: "components/report-configuration-modal",
		reportFileFormats: [],
		reportPayrollTypes: [],
		reportColumnTypes: [],
		reportFileFormat: _ember["default"].computed.oneWay("reportFileFormats.firstObject.value"),
		isLoading: false,
		displayDate: false,
		searchByRun: true,
		eventLogger: _ember["default"].inject.service(),

		// prob should be divided into two modal
		reportEmployees: [],
		reportEmployeesWithStatus: [],
		reportPayrolls: [],
		reportWorkLocations: [],
		includeAmendmentRuns: false,
		includeInitRuns: true,
		includeZeroWage: false,
		showEmployeeSelectionField: (function () {
			// is client app AND
			// type is EmployeeHistoryReport OR is EmployeePaycheckReport
			return !this.get("isConsoleApp") && (this.get("isEmployeeHistoryReport") || this.get("isEmployeeDetailReport"));
		}).property("isConsoleApp", "reportType"),
		showEmployeeSelectionFieldWithStatus: (function () {
			return !this.get("isConsoleApp") && (this.get("isEmployeeDetailsReportBeta") || this.get("isHistorySummaryReport") || this.get("isGarnishmentsAuditReport") || this.get("isGarnishmentsSetupReport"));
		}).property("reportType"),

		showPayrollSelectionField: (function () {
			// is client app AND
			// type is PayrollHistoryReport OR os PayrollPaycheckReport
			return !this.get("isConsoleApp") && (this.get("isPayrollSummaryReport") || this.get("isPayrollSummaryReportBeta") || this.get("isClientTaxPaymentsBetaReport") || this.get("isPayrollDetailReport") || this.get("isPayrollDetailReportBeta") || this.get("isContractorPaymentReport") || this.get("isPayrollRegisterReport") || this.get("isWageAndTaxSummaryReport") || this.get("isAllInOneReportBeta") || this.get("isDeductionAndContributionReport") || this.get("isTaxLiabilityReport") || this.get("isLaborDistributionReport") || this.get("isNewLaborDistributionReport") || this.get("isLaborDistributionDetailReport") || this.get("isReportedTips")) && this.get("searchByRun");
		}).property("isConsoleApp", "reportType", "searchByRun"),
		showCompanyLocationField: (function () {
			// only when report type is payroll summary and there is more than one location,
			// we have to show company locations
			return (this.get("isPayrollSummaryReportBeta") || this.get("isClientTaxPaymentsBetaReport") || this.get("isPayrollDetailReportBeta") || this.get("isContractorPaymentReport") || this.get("isPayrollRegisterReport") || this.get("isWageAndTaxSummaryReport") || this.get("isDeductionAndContributionReport") || this.get("isTaxLiabilityReport") || this.get("isHistorySummaryReport") || this.get("isAllInOneReportBeta") || this.get("isGarnishmentsAuditReport") || this.get("isGarnishmentsSetupReport") || this.get("isLaborDistributionDetailReport") || this.get("isNewLaborDistributionReport") || this.get("isReportedTips")) && this.get("reportWorkLocations").length > 1;
		}).property("reportType", "reportWorkLocations"),
		showPayRunToggle: (function () {
			return this.get("isPayrollSummaryReport") || this.get("isPayrollSummaryReportBeta") || this.get("isClientTaxPaymentsBetaReport") || this.get("isPayrollDetailReportBeta") || this.get("isContractorPaymentReport") || this.get("isLaborDistributionReport") || this.get("isNewLaborDistributionReport") || this.get("isPayrollRegisterReport") || this.get("isWageAndTaxSummaryReport") || this.get("isDeductionAndContributionReport") || this.get("isAllInOneReportBeta") || this.get("isTaxLiabilityReport") || this.get("isLaborDistributionDetailReport") || this.get("isReportedTips");
		}).property("reportType"),

		noSelectedEmployeeId: (function () {
			return this.get("showEmployeeSelectionField") && !this.get("selectedEmployeeId");
		}).property("showEmployeeSelectionField", "selectedEmployeeId"),
		noSelectedPayrollId: (function () {
			return this.get("showPayrollSelectionField") && !this.get("selectedPayrollId");
		}).property("showPayrollSelectionField", "selectedPayrollId"),
		reportTitle: (function () {
			var words = this.get("reportType").replace("Employee", "Individual").split(/(?=[A-Z])/);
			return words.join(" ");
		}).property("reportType"),

		app: _ember["default"].computed(function () {
			return "client";
		}),
		isConsoleApp: _ember["default"].computed(function () {
			return this.get("app") == "console";
		}),

		hideFileType: (function () {
			// client beta reports
			return this.get("isBetaReport") || this.get("isTaxPackageReport") || this.get("isW2PreviewReport") || this.get("isClientTaxPaymentsReport");
		}).property("isBetaReport", "isTaxPackageReport", "isW2PreviewReport", "isClientTaxPaymentsReport"),

		showLaborAggregate: (function () {
			return this.get("isLaborDistributionReport") || this.get("isLaborDistributionDetailReport") || this.get("isNewLaborDistributionReport");
		}).property("reportType"),

		shouldShowPayrollTypeIncludeOptions: (function () {
			return this.get("isTaxFilingReport");
		}).property("reportType"),

		showAggregateCheckBox: (function () {
			var systemGroupTypes = this.get("systemGroupTypes");
			var nonSystemGroupTypes = this.get("nonSystemGroupTypes");
			var userDefinedGroupTypes = this.get("userDefinedGroupTypes").objects;
			nonSystemGroupTypes = nonSystemGroupTypes.filter(function (item) {
				for (var i = 0, len = userDefinedGroupTypes.length; i < len; i++) {
					if (userDefinedGroupTypes[i].groupTypeId.toString() == item.id) {
						return true;
					}
				}
				return false;
			});

			//Sorting:Asc
			var sortedSystemGroupTypes = systemGroupTypes.sort(function (x, y) {
				var nameA = x.name.toLowerCase(),
				    nameB = y.name.toLowerCase();
				if (nameA < nameB) {
					return -1;
				}
				return 0;
			});

			var sortednonSystemGroupTypes = nonSystemGroupTypes.sort(function (x, y) {
				var nameA = x.name.toLowerCase(),
				    nameB = y.name.toLowerCase();
				if (nameA < nameB) {
					return -1;
				}
				return 0;
			});

			var Options = sortedSystemGroupTypes.concat(sortednonSystemGroupTypes);
			return Options;
		}).property("systemGroupTypes", "nonSystemGroupTypes", "userDefinedGroupTypes", "islaborDistributionEnabled"),

		showAggregateMultiSelect: (function () {
			var systemGroupTypes = this.get("systemGroupTypes");
			var selectedAggregateOption = this.get("selectedLaborAggregate");
			var nonSystemGroupTypes = this.get("nonSystemGroupTypes");
			var userDefinedGroupTypes = this.get("userDefinedGroupTypes").objects;
			nonSystemGroupTypes = nonSystemGroupTypes.filter(function (item) {
				for (var i = 0, len = userDefinedGroupTypes.length; i < len; i++) {
					if (userDefinedGroupTypes[i].groupTypeId.toString() == item.id) {
						return true;
					}
				}
				return false;
			});

			var allOptions = systemGroupTypes.concat(nonSystemGroupTypes);
			var multiOptions = allOptions.map(function (x) {
				var id = x.id;
				var text = x.name;
				return _ember["default"].Object.create({
					id: id, text: text
				});
			});

			if (multiSelectOptions) {
				multiSelectOptions = null;
			}

			var multiSelectOptions = multiOptions.filter(function (options) {
				return options.text !== selectedAggregateOption;
			});
			return multiSelectOptions;
		}).property("systemGroupTypes", "nonSystemGroupTypes", "userDefinedGroupTypes", "selectedLaborAggregate", "islaborDistributionEnabled"),

		reportTypes: [{
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollJournal,
			url: "/custom_api/z_payroll/createAllRunsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeInformation,
			url: "/custom_api/z_payroll/createAllEmployeeInformationReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeDetailsReport,
			url: "/custom_api/z_payroll/createAllEmployeeInformationBetaReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.GarnishmentsAuditReport,
			url: "/custom_api/z_payroll/GarnishmentsAuditReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.GarnishmentsSetupReport,
			url: "/custom_api/z_payroll/GarnishmentsSetupReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.YearToDateReport,
			url: "/custom_api/z_payroll/createSumOfAllDetailsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.BankAccountTransaction,
			url: "/custom_api/z_payroll/createBankTransactionReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxTransactionReport,
			url: "/custom_api/z_payroll/createTaxTransactionReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxPaymentsReport,
			url: "/custom_api/z_payroll/createTaxPaymentsReport/",
			extraFields: [{
				isNumberField: true,
				key: "zpCompany_id",
				value: "",
				label: "ZPCompany ID",
				helpText: "Optional"
			}]
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.InboundPaymentsReport,
			url: "/custom_api/z_payroll/createInboundPaymentsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.OutboundPaymentsReport,
			url: "/custom_api/z_payroll/createOutboundPaymentsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.DailyReconTaxPaymentsReport,
			url: "/custom_api/z_payroll/createDailyReconTaxPaymentsReport/",
			extraFields: [{
				isNumberField: true,
				key: "zpCompany_id",
				value: "",
				label: "ZPCompany ID",
				helpText: "Optional"
			}]
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxDataDump,
			url: "/custom_api/z_payroll/createTaxDataDump/",
			extraFields: [{
				isNumberField: true,
				key: "zpCompany_id",
				value: "",
				label: "ZPCompany ID",
				helpText: "Optional"
			}]
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxLiabilityReport,
			url: "/custom_api/z_payroll/TaxLiabilityReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.DeductionAndContributionReport,
			url: "/custom_api/z_payroll/DeductionAndContributionReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxPaymentsBetaReport,
			url: "/custom_api/z_payroll/TaxPaymentsBetaReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.WageAndTaxSummaryReport,
			url: "/custom_api/z_payroll/WageAndTaxSummaryReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ClientFilingReport,
			url: "/custom_api/z_payroll/createClientFilingReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.NewHireReport,
			url: "/custom_api/z_payroll/createNewHireFilingsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxFilingReport,
			url: "/custom_api/z_payroll/createTaxFilingReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxPackage,
			url: "/custom_api/z_payroll/createTaxPackage/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.AllInOneReport,
			url: "/custom_api/z_payroll/createAllRunsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.AllInOneReportBeta,
			url: "/custom_api/z_payroll/createAllInOneReportBeta/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.CompanyTaxCreditsReport,
			url: "/custom_api/z_payroll/createCompanyTaxCreditsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.JurisdictionPaymentReport,
			url: "/custom_api/z_payroll/createJurisdictionPaymentReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.CompanyDebitVariance,
			url: "/custom_api/z_payroll/createCompanyDebitVarianceReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollWireReport,
			url: "/custom_api/z_payroll/createPayrollWireReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeRiskCodeReport,
			url: "/custom_api/z_payroll/createEmployeeRiskCodeReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PaymentBackfillCompanyVarianceReport,
			url: "/custom_api/z_payroll/getPaymentBackfillCompanyVarianceReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.HistorySummaryReport,
			url: "/custom_api/z_payroll/HistorySummaryReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollRegisterReport,
			url: {
				withDepartment: "/custom_api/z_payroll/PayrollRegisterReportByDept/",
				withoutDepartment: "/custom_api/z_payroll/PayrollRegisterReport/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeHistoryReport,
			url: "/custom_api/z_payroll/EmployeeHistoryReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.CompanyHistoryReport,
			url: "/custom_api/z_payroll/CompanyHistoryReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxNoticeReport,
			url: "/custom_api/z_payroll/TaxNoticeReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeDetailReport,
			url: "/custom_api/z_payroll/EmployeeDetailReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.CompanyDetailReport,
			url: "/custom_api/z_payroll/CompanyDetailReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollDetailReport,
			url: "/custom_api/z_payroll/PayrollDetailReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollDetailReportBeta,
			url: {
				run: "/custom_api/z_payroll/PayrollDetailBetaReportByRun/",
				company: "/custom_api/z_payroll/PayrollDetailBetaReportByCompany/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ContractorPaymentReport,
			url: {
				run: "/custom_api/z_payroll/ContractorPaymentReportByRun/",
				company: "/custom_api/z_payroll/ContractorPaymentReportByCompany/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.LaborDistributionReport,
			url: {
				run: "/custom_api/z_payroll/LaborDistributionReportByRun/",
				company: "/custom_api/z_payroll/LaborDistributionReportByCompany/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.LaborDistributionDetailReport,
			url: "/custom_api/z_payroll/LaborDistributionDetailReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.NewLaborDistributionReport,
			url: {
				run: "/custom_api/z_payroll/LaborDistribution/",
				company: "/custom_api/z_payroll/LaborDistribution/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollSummaryReport,
			url: {
				run: "/custom_api/z_payroll/PayrollSummaryReportByRun/",
				company: "/custom_api/z_payroll/PayrollSummaryReportByCompany/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollSummaryReportBeta,
			url: {
				run: "/custom_api/z_payroll/PayrollSummaryBetaReportByRun/",
				company: "/custom_api/z_payroll/PayrollSummaryBetaReportByCompany/"
			}
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.LiabilitiesReport,
			url: "/custom_api/z_payroll/LiabilitiesReport/",
			extraFields: [{
				isNumberField: true,
				key: "zpCompany_id",
				value: "",
				label: "ZPCompany ID",
				helpText: "Optional"
			}]
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.W2PreviewReport
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.W2PreviewGenerate,
			url: "/custom_api/zfp/v1/generation"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ClientTaxPaymentsReport,
			url: "/custom_api/z_payroll/createClientTaxPaymentsReport/"
		}, {
			type: _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ReportedTips,
			url: "/custom_api/z_payroll/ReportedTipsReport/"
		}],

		isBetaReport: _ember["default"].computed.or("isEmployeeHistoryReport", "isEmployeeDetailReport", "isCompanyHistoryReport", "isCompanyDetailReport", "isPayrollSummaryReport", "isPayrollDetailReport", "isContractorPaymentReport"),
		isReportsBetaTab: _ember["default"].computed.or("isPayrollDetailReportBeta", "isContractorPaymentReport", "isPayrollSummaryReportBeta", "isLaborDistributionReport", "isNewLaborDistributionReport", "isLaborDistributionDetailReport", "isHistorySummaryReport", "isPayrollRegisterReport", "isEmployeeDetailsReportBeta", "isWageAndTaxSummaryReport", "isDeductionAndContributionReport", "isTaxLiabilityReport", "isAllInOneReportBeta", "isClientTaxPaymentsBetaReport", "isGarnishmentsAuditReport", "isGarnishmentsSetupReport", "isLaborDistributionDetailReport", "isReportedTips"),
		showEmployeeIdField: _ember["default"].computed.or("isEmployeeHistoryReport", "isEmployeeDetailReport"),

		// certain reports should hide date
		// OR when a particular payroll is used as context
		hideDateSelection: (function () {
			var validType = this.get("isPayrollWireReport") || this.get("isEmployeeInformationReport") || this.get("isEmployeeDetailsReportBeta") || this.get("isEmployeeRiskCodeReport") || this.get("isGarnishmentsSetupReport");

			return validType || this.get("showPayrollIdField");
		}).property("reportType", "showPayrollIdField"),
		// certain reports use company
		// UNLESS when a particular run is used
		showCompanyIdField: (function () {
			var validType = this.get("isTaxPackageReport") || this.get("isAllInOneReport") || this.get("isYearToDateReport") || this.get("isEmployeeRiskCodeReport") || this.get("isBankTransactionReport") || this.get("isPaymentBackfillCompanyVarianceReport") || this.get("isCompanyHistoryReport") || this.get("isCompanyDetailReport") || this.get("isPayrollSummaryReport") || this.get("isPayrollSummaryReportBeta") || this.get("isPayrollDetailReportBeta") || this.get("isContractorPaymentReport") || this.get("isPayrollRegisterReport") || this.get("isWageAndTaxSummaryReport") || this.get("isClientTaxPaymentsBetaReport") || this.get("isDeductionAndContributionReport") || this.get("isTaxLiabilityReport") || this.get("isW2PreviewReport") || this.get("isEmployeeInformation") || this.get("isEmployeeDetailsReportBeta") || this.get("isLaborDistributionReport") || this.get("isNewLaborDistributionReport") || this.get("isGarnishmentsAuditReport") || this.get("isGarnishmentsSetupReport") || this.get("isTaxFilingReport");
			return validType && !this.get("showPayrollIdField") && !this.get("isEmployerOrEmployeeReportSelected");
		}).property("reportType", "showPayrollIdField"),
		isCompanyIdFieldRequired: (function () {
			return !this.get("isTaxFilingReport");
		}).property("reportType"),
		showPayrollIdField: (function () {
			var validType = this.get("isPayrollDetailReport") || this.get("isPayrollDetailReportBeta") || this.get("isContractorPaymentReport") || this.get("isPayrollSummaryReport") || this.get("isPayrollRegisterReport") || this.get("isWageAndTaxSummaryReport") || this.get("isClientTaxPaymentsBetaReport") || this.get("isDeductionAndContributionReport") || this.get("isAllInOneReportBeta") || this.get("isTaxLiabilityReport") || this.get("isLaborDistributionReport") || this.get("isNewLaborDistributionReport") || this.get("isLaborDistributionDetailReport") || this.get("isPayrollSummaryReportBeta") || this.get("isReportedTips");
			return validType && this.get("searchByRun");
		}).property("reportType", "searchByRun"),

		companyIdForReport: (function () {
			if (this.get("isConsoleApp") && this.get("isTaxPackageReport")) {
				// console tax package report
				return 1260;
			}
		}).property("isConsoleApp", "isTaxPackageReport"),

		isTaxPackageReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxPackage;
		}).property("reportType"),
		isAllInOneReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.AllInOneReport;
		}).property("reportType"),
		isAllInOneReportBeta: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.AllInOneReportBeta;
		}).property("reportType"),
		isPayrollJournal: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollJournal;
		}).property("reportType"),
		isTaxFilingReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxFilingReport;
		}).property("reportType"),
		isJurisdictionReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.JurisdictionPaymentReport;
		}).property("reportType"),
		isInboundPaymentsReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.InboundPaymentsReport;
		}).property("reportType"),
		isOutboundPaymentsReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.OutboundPaymentsReport;
		}).property("reportType"),
		isDailyReconTaxPaymentsReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.DailyReconTaxPaymentsReport;
		}).property("reportType"),
		isTaxPaymentsReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxPaymentsReport;
		}).property("reportType"),
		isClientTaxPaymentsBetaReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxPaymentsBetaReport;
		}).property("reportType"),
		isLaborDistributionDetailReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.LaborDistributionDetailReport;
		}).property("reportType"),
		isEmployerDebitsReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployerDebitsReport;
		}).property("reportType"),
		isEmployeeInformationReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeInformation;
		}).property("reportType"),
		isEmployeeDetailsReportBeta: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeDetailsReport;
		}).property("reportType"),
		isEmployeeCreditsReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeCreditsReport;
		}).property("reportType"),
		isNewHireFilingReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.NewHireReport;
		}).property("reportType"),
		isYearToDateReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.YearToDateReport;
		}).property("reportType"),
		isHistorySummaryReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.HistorySummaryReport;
		}).property("reportType"),
		isPayrollRegisterReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollRegisterReport;
		}).property("reportType"),
		isWageAndTaxSummaryReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.WageAndTaxSummaryReport;
		}).property("reportType"),
		isDeductionAndContributionReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.DeductionAndContributionReport;
		}).property("reportType"),
		isTaxLiabilityReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxLiabilityReport;
		}).property("reportType"),
		isBankTransactionReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.BankAccountTransaction;
		}).property("reportType"),
		isEmployeeRiskCodeReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeRiskCodeReport;
		}).property("reportType"),
		isGarnishmentsAuditReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.GarnishmentsAuditReport;
		}).property("reportType"),
		isGarnishmentsSetupReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.GarnishmentsSetupReport;
		}).property("reportType"),
		isPaymentBackfillCompanyVarianceReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PaymentBackfillCompanyVarianceReport;
		}).property("reportType"),
		isPayrollWireReport: _ember["default"].computed("reportType", function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollWireReport;
		}),
		isEmployeeHistoryReport: _ember["default"].computed("reportType", function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeHistoryReport;
		}),
		isCompanyHistoryReport: _ember["default"].computed("reportType", function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.CompanyHistoryReport;
		}),
		isTaxNoticeReport: _ember["default"].computed("reportType", function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.TaxNoticeReport;
		}),
		isW2PreviewReport: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.W2PreviewReport;
		}).property("reportType"),
		isW2PreviewGenerate: (function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.W2PreviewGenerate;
		}).property("reportType"),
		isClientTaxPaymentsReport: _ember["default"].computed("reportType", function () {
			return this.get("reportType") === _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ClientTaxPaymentsReport;
		}),
		isEmployeeDetailReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.EmployeeDetailReport),
		isCompanyDetailReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.CompanyDetailReport),
		isPayrollDetailReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollDetailReport),
		isPayrollDetailReportBeta: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollDetailReportBeta),
		isPayrollSummaryReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollSummaryReport),
		isPayrollSummaryReportBeta: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.PayrollSummaryReportBeta),
		isLiabilitiesReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.LiabilitiesReport),
		isLaborDistributionReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.LaborDistributionReport),
		isNewLaborDistributionReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.NewLaborDistributionReport),
		isContractorPaymentReport: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ContractorPaymentReport),
		isReportedTips: _ember["default"].computed.equal("reportType", _componentLibraryConstantsZPayrollZPayrollReportTypes.REPORT_TYPES.ReportedTips),

		zPayrollRunId: null,
		jurisdictionValue: null,
		stateValue: null,
		statePrompt: null,
		collectorPrompt: null,
		collectorValue: null,
		taxFilingData: null,
		allJurisdictionData: null,
		isLoadingStatesOrCollectors: false,
		isLoadingReportDownloadList: false,
		isLoadingNewHireReportList: false,
		isDeletingRecord: false,
		refreshInterval: null,
		showGenerateButton: (function () {
			return this.get("isTaxFilingReport") || this.get("isNewHireFilingReport");
		}).property(),
		reportsDownloadList: (function () {
			return _ember["default"].A();
		}).property(),
		newHireReportsDownloadList: (function () {
			return _ember["default"].A();
		}).property(),
		newHireReportFilingTypes: (function () {
			return [{
				text: "Select All",
				id: NEW_HIRE_REPORT_SELECT_ALL
			}, {
				text: "All State New Hire Report",
				id: NEW_HIRE_REPORT_ALL_STATE
			}, {
				text: "AL New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_AL
			}, {
				text: "AZ New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_AZ
			}, {
				text: "CA New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_CA
			}, {
				text: "CO New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_CO
			}, {
				text: "CT New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_CT
			}, {
				text: "DC New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_DC
			}, {
				text: "DE New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_DE
			}, {
				text: "FL New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_FL
			}, {
				text: "GA New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_GA
			}, {
				text: "IL New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_IL
			}, {
				text: "MA New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_MA
			}, {
				text: "MI New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_MI
			}, {
				text: "NJ New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_NJ
			}, {
				text: "NM New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_NM
			}, {
				text: "NV New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_NV
			}, {
				text: "NY New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_NY
			}, {
				text: "PA New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_PA
			}, {
				text: "TX New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_TX
			}, {
				text: "UT New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_UT
			}, {
				text: "VA New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_VA
			}, {
				text: "WA New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_WA
			}, {
				text: "WY New Hire e-File (Zip)",
				id: NEW_HIRE_REPORT_WY
			}];
		}).property(),
		jurisdictions: (function () {
			return this.get("taxFilingData." + this.get("taxFilingType.name") + ".jurisdictions");
		}).property("taxFilingType"),
		allJurisdictions: (function () {
			return this.get("allJurisdictionData.jurisdictions");
		}).property(),
		taxFilingReportTypes: (function () {
			var selectedJurisdiction = this.get("jurisdictionValue.name");
			var attrStr = "taxFilingData.manual.reportTypes";
			return this.get(attrStr);
		}).property("taxFilingData.manual.reportTypes"),
		taxFilingType: _ember["default"].computed.oneWay("taxFilingTypes.firstObject"),
		taxFilingReportType: _ember["default"].computed.oneWay("taxFilingReportTypes.firstObject"),
		isEmployerOrEmployeeReportSelected: (function () {
			var selectedTaxFilingReportType = this.get("taxFilingReportType.name");
			var isEmployerOrEmployeeReportSelected = selectedTaxFilingReportType === "LOCAL_EMPLOYER" || selectedTaxFilingReportType === "LOCAL_EMPLOYEE";
			this.send("getDownlodReportsList");
			return isEmployerOrEmployeeReportSelected;
		}).property("taxFilingReportType"),

		selectedQuarter: _ember["default"].computed.oneWay("quartersDetails.lastObject"),

		isHireQuarterSelected: (function () {
			this.send("getNewHireReportsList");
		}).observes("selectedQuarter"),

		shouldSelectAllReports: (function () {
			var _this = this;

			if (this.get("selectedNewHireReportFilingType").includes(NEW_HIRE_REPORT_SELECT_ALL)) {
				(function () {
					var selectedReportTypes = _this.get("selectedNewHireReportFilingType");
					var filteredReports = _this.get("newHireReportFilingTypes").filter(function (item) {
						return item.id !== NEW_HIRE_REPORT_SELECT_ALL;
					});
					selectedReportTypes.pop(NEW_HIRE_REPORT_SELECT_ALL);
					filteredReports.forEach(function (report) {
						if (!selectedReportTypes.includes(report.id)) {
							selectedReportTypes.push(report.id);
						}
					});
				})();
			}
		}).observes("selectedNewHireReportFilingType"),

		allStates: (function () {
			var _this2 = this;

			this.setProperties({
				statePrompt: "Loading States...",
				isLoadingStatesOrCollectors: true
			});
			var states = _ember["default"].ArrayProxy.create({
				content: [{
					name: "NA",
					verboseName: "No States"
				}]
			});
			_ember["default"].ajax({
				url: "/custom_api/z_payroll/stateInfo/",
				type: "GET",
				contentType: "application/json"
			}).then(function (data) {
				_this2.setProperties({
					statePrompt: "Select State",
					isLoadingStatesOrCollectors: false
				});
				states.set("content", JSON.parse(data));
			})["catch"](function (error) {
				_this2.set("isLoadingStatesOrCollectors", false);
				_componentLibraryComponentsNotificationModal["default"].show({
					notificationType: "error",
					title: "Error Occured",
					message: "Could not fetch states due to error --  " + error
				});
			});
			return states;
		}).property(),
		allCollectors: (function () {
			var _this3 = this;

			var collectors = _ember["default"].ArrayProxy.create({
				content: ["No Collectors"]
			});
			var selectedJurisdiction = this.get("stateValue.name");
			var selectedStateName = this.get("stateValue.verboseName");
			if (this.get("isEmployerOrEmployeeReportSelected") && this.get("stateValue.name") !== null) {
				this.setProperties({
					collectorPrompt: "Loading Collectors...",
					isLoadingStatesOrCollectors: true
				});
				_ember["default"].ajax({
					url: "/custom_api/z_payroll/collectorInfo/",
					type: "POST",
					data: JSON.stringify({
						stateName: selectedJurisdiction,
						stateVerboseName: selectedStateName
					}),
					contentType: "application/json"
				}).then(function (data) {
					if (data[selectedJurisdiction] === undefined || data[selectedJurisdiction].length === 0) {
						collectors.set("content", ["No Collectors"]);
					} else {
						_this3.setProperties({
							collectorValue: null,
							collectorPrompt: "Select Collector"
						});
						collectors.set("content", data[selectedJurisdiction]);
					}
					_this3.set("isLoadingStatesOrCollectors", false);
				})["catch"](function (error) {
					_componentLibraryComponentsNotificationModal["default"].show({
						notificationType: "error",
						title: "Error Occured",
						message: "Could not fetch collectors due to error --  " + error
					});
					_this3.set("isLoadingStatesOrCollectors", false);
				});
			} else {
				collectors.set("content", ["No Collectors"]);
			}
			return collectors;
		}).property("stateValue.name"),
		newHireReportFilingType: _ember["default"].computed.oneWay("newHireReportFilingTypes.firstObject"),
		shouldShowDateOffset: _ember["default"].computed.or("isTaxPaymentsReport", "isEmployerDebitsReport", "isEmployeeCreditsReport", "isInboundPaymentsReport", "isOutboundPaymentsReport", "isDailyReconTaxPaymentsReport"),

		dateOffsetTypes: (function () {
			if (this.get("isTaxPaymentsReport")) {
				return [{
					verboseName: "Ledger Due Date",
					value: "dueDate"
				}, {
					verboseName: "Run Approval Deadline",
					value: "approvalDeadline"
				}, {
					verboseName: "Check Date",
					value: "checkDate"
				}];
			} else if (this.get("isEmployerDebitsReport")) {
				return [{
					verboseName: "Ledger Due Date",
					value: "dueDate"
				}, {
					verboseName: "Run Approval Deadline",
					value: "approvalDeadline"
				}];
			} else if (this.get("isEmployeeCreditsReport")) {
				return [{
					verboseName: "Ledger Due Date",
					value: "dueDate"
				}, {
					verboseName: "Run Approval Deadline",
					value: "approvalDeadline"
				}];
			} else if (this.get("isInboundPaymentsReport")) {
				return [{
					verboseName: "Payment Realization Date",
					value: "realizationDate"
				}, {
					verboseName: "Payment Execution Date",
					value: "executionDate"
				}, {
					verboseName: "Ledger Due Date",
					value: "dueDate"
				}, {
					verboseName: "Run Approval Deadline",
					value: "approvalDeadline"
				}];
			} else if (this.get("isOutboundPaymentsReport")) {
				return [{
					verboseName: "Payment Realization Date",
					value: "realizationDate"
				}, {
					verboseName: "Payment Execution Date",
					value: "executionDate"
				}, {
					verboseName: "Ledger Due Date",
					value: "dueDate"
				}, {
					verboseName: "Run Approval Deadline",
					value: "approvalDeadline"
				}];
			} else if (this.get("isDailyReconTaxPaymentsReport")) {
				return [{
					verboseName: "Payment Realization Date",
					value: "realizationDate"
				}, {
					verboseName: "Payment Execution Date",
					value: "executionDate"
				}, {
					verboseName: "Ledger Due Date",
					value: "dueDate"
				}, {
					verboseName: "Check Date",
					value: "checkDate"
				}, {
					verboseName: "Run Approval Deadline",
					value: "approvalDeadline"
				}];
			}
		}).property(),
		reportTypeData: (function () {
			return this.get("reportTypes").findBy("type", this.get("reportType"));
		}).property("reportType"),

		reportDateOffsetType: _ember["default"].computed.oneWay("dateOffsetTypes.firstObject"),
		startDate: _ember["default"].computed("displayDate", function () {
			var date = (0, _componentLibraryUtilsGlobals.moment)();
			date.subtract(1, "months");
			return date.format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}),
		endDate: _ember["default"].computed("displayDate", function () {
			return (0, _componentLibraryUtilsGlobals.moment)().format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
		}),

		filteredReportEmployees: _ember["default"].computed("selectedCompanyLocationIds", function () {
			var locationIds = this.selectedCompanyLocationIds;
			var employeeChoices = this.get("reportEmployeesWithStatus");
			if (locationIds && locationIds.length) {
				return employeeChoices.filter(function (employee) {
					return locationIds.includes(employee.locationId);
				});
			} else {
				return employeeChoices;
			}
		}),

		quartersDetails: (function () {
			var allQuarters = [];
			var numOfQuarters = Math.floor((0, _componentLibraryUtilsGlobals.moment)().month() / 3) + 1;
			if (this.get("isTaxPackageReport")) {
				numOfQuarters = 3;
			}
			var pastQuarter = (0, _componentLibraryUtilsGlobals.moment)(1, "Q").subtract(9, "Q");
			for (var qn = 0; qn < 8 + numOfQuarters; qn++) {
				// create all quarters
				pastQuarter.add(1, "Q");
				var quarterStartDateString = pastQuarter.startOf("quarter").format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var quarterEndDateString = pastQuarter.endOf("quarter").format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
				var quarterName = pastQuarter.year().toString() + " / Q" + (qn % 4 + 1).toString();
				allQuarters.push({
					startDate: quarterStartDateString,
					endDate: quarterEndDateString,
					name: quarterName
				});
				// create YTD
				// qn: 0 - 3, 4 - 7
				if (!this.get("isTaxPackageReport") && ((qn + 1) % 4 == 0 || qn == 7 + numOfQuarters)) {
					var yearStartDateString = pastQuarter.startOf("year").format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					var yearEndDateString = pastQuarter.endOf("year").format(_componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT);
					var yearName = pastQuarter.year().toString() + " / YTD";
					allQuarters.push({
						startDate: yearStartDateString,
						endDate: yearEndDateString,
						name: yearName
					});
				}
			}
			if (this.get("isHistorySummaryReport")) {
				var years = allQuarters.filter(function (value) {
					return value.name.includes("YTD");
				});
				return years;
			}
			return allQuarters;
		}).property("displayDate"),

		fileName: (function () {
			var extension = "xlsx";
			var fileType = this.get("reportFileFormat");
			var startDate = this.get("startDate").split("/").join("");
			var endDate = this.get("endDate").split("/").join("");
			var d = new Date();
			var timeStamp = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + "T" + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + " " + d.toTimeString().split(" ")[1];
			if (fileType !== "xls") {
				extension = fileType;
			}
			var reportType = this.get("reportTypeData");

			if (this.get("isTaxPackageReport")) {
				extension = "zip";
			}
			if (this.get("isPayrollDetailReport") || this.get("isContractorPaymentReport") || this.get("isCompanyDetailReport") || this.get("isEmployeeDetailReport") || this.get("isCompanyHistoryReport") || this.get("isEmployeeHistoryReport") || this.get("isTaxNoticeReport") || this.get("isPayrollSummaryReport") || this.get("isClientTaxPaymentsReport")) {
				extension = "xlsx";
			}

			var name = reportType.type;

			if (this.get("isReportsBetaTab")) {
				name = name + "_" + startDate + "_" + endDate + "_" + timeStamp;
			}
			return name + "." + extension;
		}).property("reportFileFormat", "newHireReportFilingType", "isNewHireFilingReport", "isCompanyHistoryReport", "isEmployeeHistoryReport", "isClientTaxPaymentsReport"),

		supportedReportFileFormats: (function () {
			// PDF client report is supported for only PayrollJournal report
			var availableReports = this.get("reportFileFormats");
			if (!this.get("isPayrollJournal") && !this.get("isAllInOneReport")) {
				availableReports = availableReports.rejectBy("value", "pdf");
			}
			if (!this.get("isNewHireFilingReport") && !this.get("isTaxPackageReport")) {
				availableReports = availableReports.rejectBy("value", "zip");
			}
			return availableReports;
		}).property("reportFileFormats", "reportType"),
		hasStartDateError: (function () {
			var startDate = this.get("startDate");
			var startDateMoment = (0, _componentLibraryUtilsGlobals.moment)(startDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
			return !startDateMoment.isValid();
		}).property("startDate"),
		hasEndDateError: (function () {
			var endDate = this.get("endDate");
			var endDateMoment = (0, _componentLibraryUtilsGlobals.moment)(endDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
			return !endDateMoment.isValid();
		}).property("endDate"),
		hasCompanyIdError: false,
		hasReportSelectionError: false,
		isStartDateGreaterThanEndDate: (function () {
			var startDate = this.get("startDate");
			var endDate = this.get("endDate");
			var startDateMoment = (0, _componentLibraryUtilsGlobals.moment)(startDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
			var endDateMoment = (0, _componentLibraryUtilsGlobals.moment)(endDate, _componentLibraryUtilsGlobals.AMERICAN_DATE_FORMAT, true);
			if (startDateMoment.isValid() && endDateMoment.isValid()) {
				if (startDateMoment.isAfter(endDateMoment)) {
					return true;
				}
			}
			return false;
		}).property("startDate", "endDate"),

		hasDateValidationError: _ember["default"].computed.or("hasStartDateError", "hasEndDateError", "isStartDateGreaterThanEndDate"),
		missingCompanyId: (function () {
			return this.get("showCompanyIdField") && this.get("isCompanyIdFieldRequired") && !this.get("companyIdForReport");
		}).property("companyIdForReport", "showCompanyIdField", "isCompanyIdFieldRequired"),
		missingEmployeeId: (function () {
			return this.get("showEmployeeIdField") && !this.get("employeeIdForReport");
		}).property("employeeIdForReport", "showEmployeeIdField"),

		/* company search */
		suggestionTemplate: _componentLibraryTemplatesComponentsReportConfigurationModalSearchSuggestion["default"],
		searchCompaniesCustomEvents: [],
		searchCompaniesUrl: reportSearchCompaniesUrl,
		searchCompaniesResultsTransform: function searchCompaniesResultsTransform(response) {
			var results = response.companies.map(function (x) {
				return {
					title: x.name + " [id: " + x.id + "]",
					id: x.name + " [id: " + x.id + "]"
				};
			});
			return results;
		},
		init: function init() {
			var _this4 = this;

			this._super.apply(this, arguments);
			var eventFunction = function eventFunction(evt, selectedItem) {
				_this4.set("companyIdForReport", selectedItem.id);
			};
			var selection = {
				eventName: "select",
				eventFunction: eventFunction
			};
			var change = {
				eventName: "cursorchange",
				eventFunction: eventFunction
			};
			var customEvents = [selection, change];
			this.set("searchCompaniesCustomEvents", customEvents);
			if (this.get("reportType") === "NewHireReport") {
				this.send("getNewHireReportsList");
			}
			if (this.get("reportType") === "TaxFilingReport" || this.get("reportType") === "NewHireReport") {
				var self = this;
				// the below interval is set to refresh the down
				var interval = setInterval(function () {
					if (self.get("reportType") === "TaxFilingReport") {
						self.send("getDownlodReportsList");
					} else {
						self.send("getNewHireReportsList");
					}
				}, 60000);
				this.set("refreshInterval", interval);
			}
		},

		fetchedCompanyId: _ember["default"].computed("companyIdForReport", function () {
			var companyObject = this.get("companyIdForReport");

			if (companyObject == null) {
				return null;
			}
			var regexObject = /\s\[id:\s\d+\]/;

			if (regexObject.test(companyObject)) {
				var regexId = /\d+(?=\D*$)/;
				return companyObject.match(regexId)[0];
			} else {
				return null;
			}
		}),

		w2PreviewFetchedCompanyIds: _ember["default"].computed("w2PreviewCompanyIds", function () {
			var companyIdsStr = this.get("w2PreviewCompanyIds");
			if (companyIdsStr && companyIdsStr.trim() !== "") {
				var companyIDs = companyIdsStr.trim().split(";");
				return companyIDs;
			}
			return [];
		}),

		didDestroyElement: function didDestroyElement() {
			window.clearInterval(this.get("refreshInterval"));
		},

		actions: {
			setDateSelection: function setDateSelection(dateSelection) {
				this.set("displayDate", dateSelection);
			},

			generateW2: function generateW2(laddaButton) {
				var that = this;
				var reportType = this.get("reportTypeData");
				var startDate = this.get("startDate");
				var endDate = this.get("endDate");
				var companyIDs = this.get("w2PreviewFetchedCompanyIds");

				var configurations = {
					startDate: startDate,
					endDate: endDate,
					fileType: "FED_W2_PREVIEW",
					company_ids: companyIDs
				};
				_ember["default"].ajax({
					url: reportType.url,
					type: "POST",
					data: JSON.stringify(configurations),
					contentType: "application/json",
					processData: false,
					dataType: "json"
				}).then(function (data) {
					that.send("confirm");
				})["catch"](function () {
					_componentLibraryComponentsNotificationModal["default"].show({
						notificationType: "error",
						title: "Unable to export report",
						message: "Sorry we are unable to export this report."
					});
					that.send("confirm");
				});
			},

			getDownlodReportsList: function getDownlodReportsList() {
				var _this5 = this;

				this.set("isLoadingReportDownloadList", true);
				_ember["default"].ajax({
					url: "/custom_api/z_payroll/zpreportingInfo/",
					type: "GET",
					data: {
						taxFilingReportType: this.get("taxFilingReportType.name")
					},
					contentType: "application/json"
				}).then(function (data) {
					_this5.setProperties({
						isLoadingReportDownloadList: false,
						reportsDownloadList: data
					});
				})["catch"](function (error) {
					_this5.set("isLoadingReportDownloadList", false);
					_componentLibraryComponentsNotificationModal["default"].show({
						notificationType: "error",
						title: "Error Occured",
						message: "Could not fetch download report list due to error --  " + error
					});
				});
			},
			getNewHireReportsList: function getNewHireReportsList() {
				var _this6 = this;

				this.set("isLoadingNewHireReportList", true);
				_ember["default"].ajax({
					url: "/custom_api/z_payroll/getNewHireFilingInfo/",
					type: "GET",
					data: {
						selectedQuarter: this.get("selectedQuarter")
					},
					contentType: "application/json"
				}).then(function (data) {
					_this6.setProperties({
						isLoadingNewHireReportList: false,
						_newHireReportsDownloadList: data
					});
				})["catch"](function (error) {
					_this6.set("isLoadingNewHireReportList", false);
					_componentLibraryComponentsNotificationModal["default"].show({
						notificationType: "error",
						title: "Error Occured",
						message: "Could not fetch download report list due to error --  " + error
					});
				});
			},
			"delete": function _delete(id) {
				var _this7 = this;

				_componentLibraryComponentsConfirmationModal["default"].show({
					title: "Are you sure you want to permanently delete this report?",
					message: "This can’t be undone. If you ever need this report again, you’ll have to recreate it."
				}).then(function () {
					_this7.setProperties({
						isLoadingReportDownloadList: true,
						isDeletingRecord: true,
						isLoading: true
					});
					if (_this7.get("reportType") === "TaxFilingReport") {
						_ember["default"].ajax({
							url: "/custom_api/z_payroll/zpreportingdeleteInfo/",
							type: "GET",
							data: {
								id: id
							},
							contentType: "application/json"
						}).then(function (response) {
							_this7.send("getDownlodReportsList");
							_this7.setProperties({
								isDeletingRecord: false,
								isLoading: false
							});
						})["catch"](function (error) {
							_this7.set("isLoadingReportDownloadList", false);
							_componentLibraryComponentsNotificationModal["default"].show({
								notificationType: "error",
								title: "Error Occured",
								message: "Could not delete the record due to --  " + error
							});
						});
					} else if (_this7.get("reportType") === "NewHireReport") {
						_ember["default"].ajax({
							url: "/custom_api/z_payroll/zpreportingdeleteInfo/",
							type: "GET",
							data: {
								id: id
							},
							contentType: "application/json"
						}).then(function (response) {
							_this7.send("getNewHireReportsList");
							_this7.setProperties({
								isDeletingRecord: false,
								isLoading: false
							});
						})["catch"](function (error) {
							_this7.set("isLoadingNewHireReportList", false);
							_componentLibraryComponentsNotificationModal["default"].show({
								notificationType: "error",
								title: "Error Occured",
								message: "Could not delete the record due to --  " + error
							});
						});
					}
				});
			},

			reset: function reset() {
				this.set("selectedNewHireReportFilingType").length = 0;
			},

			download: function download(laddaButton) {
				var that = this;
				this.set("isLoading", true);
				var reportType = this.get("reportTypeData");
				var fileName = this.get("fileName");
				var startDate = this.get("startDate");
				var endDate = this.get("endDate");

				if (this.get("isNewHireFilingReport")) {
					if (this.get("selectedNewHireReportFilingType")) {
						this.setProperties({
							hasReportSelectionError: false,
							isLoading: true
						});
					} else {
						this.setProperties({
							hasReportSelectionError: true,
							isLoading: false
						});
						laddaButton.stop();
						return;
					}
				}

				if (this.get("isConsoleApp") && this.get("showCompanyIdField") && this.get("isCompanyIdFieldRequired") && this.get("fetchedCompanyId") === null) {
					this.setProperties({
						hasCompanyIdError: true,
						isLoading: false
					});
					laddaButton.stop();
					return;
				}

				// check 1: date
				if (!this.get("displayDate")) {
					var quarter = this.get("selectedQuarter");
					startDate = quarter.startDate;
					endDate = quarter.endDate;
				} else {
					if (this.get("hasDateValidationError")) {
						this.set("isLoading", false);
						laddaButton.stop();
						return;
					}
				}

				// check 2: report id (console)
				if (this.get("isConsoleApp") && (this.get("missingCompanyId") || this.get("missingEmployeeId"))) {
					this.set("isLoading", false);
					laddaButton.stop();
					return;
				}

				// check 3: report id (client)
				if (!this.get("isConsoleApp") && (this.get("noSelectedEmployeeId") || this.get("noSelectedPayrollId"))) {
					this.set("isLoading", false);
					laddaButton.stop();
					return;
				}

				var extraFields = {};
				if (typeof reportType.extraFields != "undefined" && reportType.extraFields.constructor === Array) {
					for (var _i = 0; _i < reportType.extraFields.length; _i++) {
						var extra = reportType.extraFields[_i];
						extraFields[extra.key] = extra.value;
					}
				}

				if (this.get("shouldShowDateOffset")) {
					extraFields["dateOffsetType"] = this.get("reportDateOffsetType.value");
				}

				var companyNewId = null;
				var employeeNewId = null;
				var runNewId = null;
				var companyLocationIds = null;
				var employeeIds = null;
				var includeOptions = null;
				var includeOptionsById = null;
				var aggregateBy = null;
				if (this.get("isConsoleApp")) {
					companyNewId = null;
					if (this.get("showCompanyIdField") && this.get("companyIdForReport")) {
						companyNewId = this.get("companyIdForReport").id;
					}
					employeeNewId = this.get("showEmployeeIdField") ? this.get("employeeIdForReport") : null;
					runNewId = this.get("showPayrollIdField") ? this.get("payrollIdForReport") : null;
				} else {
					companyNewId = this.get("companyIdForReport"); // client app: always pull an implied company
					employeeNewId = this.get("showEmployeeSelectionField") ? this.get("selectedEmployeeId") : null;
					runNewId = this.get("showPayrollSelectionField") ? this.get("selectedPayrollId") : null;
				}
				companyLocationIds = this.get("showCompanyLocationField") ? this.get("selectedCompanyLocationIds") : null;
				employeeIds = this.get("showEmployeeSelectionFieldWithStatus") ? this.get("selectedEmployeeIds") : null;

				if (this.get("isNewLaborDistributionReport") || this.get("isLaborDistributionDetailReport")) {
					// Reverse Mapping from id to name
					var includeOpts = this.get("selectedIncludeOptions") || [];
					var allOpts = this.get("showAggregateMultiSelect");
					var finalIncludeOptions = [];
					for (var i = 0; i < allOpts.length; i++) {
						for (var j = 0; j < includeOpts.length; j++) {
							if (allOpts[i].id == includeOpts[j]) {
								finalIncludeOptions.push(allOpts[i].text);
							}
						}
					}
					includeOptions = this.get("showLaborAggregate") ? finalIncludeOptions : null;
					includeOptionsById = this.get("showLaborAggregate") ? this.get("selectedIncludeOptions") : null;

					aggregateBy = this.get("showLaborAggregate") ? this.get("selectedLaborAggregate") : null;
				}

				this.get('eventLogger').log('payroll_report_downloaded_fileType', {
					reportName: this.get("reportTitle"),
					fileType: this.get("reportFileFormat"),
					isConsoleApp: this.get("isConsoleApp")
				});
				this.get('eventLogger').log('bi_reports', {
					biAppSection: 'premadeReportsPayroll',
					biAppActionType: 'download',
					reportName: this.get("reportTitle"),
					fileType: this.get("reportFileFormat")
				});

				var configurations = {
					startDate: startDate,
					endDate: endDate,
					aggregateBy: aggregateBy,
					includeOptions: includeOptions,
					includeOptionsById: includeOptionsById,
					columnTypes: this.get("reportColumnTypes").getEach("value"),
					fileType: this.get("reportFileFormat"),
					extraFields: extraFields,
					company_id: companyNewId,
					employee_id: employeeNewId,
					employee_ids: employeeIds,
					run_id: runNewId,
					company_location_ids: companyLocationIds,
					zPayrollRunId: this.get("zPayrollRunId")
				};

				if (this.get("isConsoleApp") && this.get("showCompanyIdField")) {
					configurations["company_id"] = this.get("fetchedCompanyId");
				}

				if (this.get("isNewHireFilingReport")) {
					configurations["reportType"] = this.get("selectedNewHireReportFilingType");
					configurations.selectedQuarter = this.get("selectedQuarter.name");
				}
				if (this.get("isJurisdictionReport") || this.get("isTaxFilingReport")) {
					configurations["jurisdiction"] = this.get("jurisdictionValue.name");
					if (this.get("isTaxFilingReport")) {
						var collectorIdValue = this.get("collectorValue") == "No Collectors" ? null : this.get("collectorValue");
						configurations["filingType"] = this.get("taxFilingReportType.name");
						configurations["stateName"] = this.get("stateValue.name");
						configurations["stateVerboseName"] = this.get("stateValue.verboseName");
						configurations["collectorId"] = collectorIdValue;
						configurations["includeAmendmentRuns"] = this.get("includeAmendmentRuns");
						configurations["includeInitRuns"] = this.get("includeInitRuns");
						configurations["includeZeroWage"] = this.get("includeZeroWage");
					}
				}

				var requestUrl = reportType.url;
				if (this.get("isPayrollSummaryReport") || this.get("isPayrollSummaryReportBeta") || this.get("isPayrollDetailReportBeta") || this.get("isContractorPaymentReport") || this.get("isLaborDistributionReport") || this.get("isNewLaborDistributionReport")) {
					requestUrl = this.get("searchByRun") ? requestUrl.run : requestUrl.company;
				}

				// payroll register report we should not display the start date and end date when we search by run
				if ((this.get("isPayrollRegisterReport") || this.get("isDeductionAndContributionReport") || this.get("isClientTaxPaymentsBetaReport") || this.get("isWageAndTaxSummaryReport") || this.get("isTaxLiabilityReport") || this.get("isReportedTips")) && this.get("searchByRun")) {
					delete configurations.startDate;
					delete configurations.endDate;
				}

				// attach new field in configurations for payroll register report
				if (this.get("isPayrollRegisterReport") || this.get("isDeductionAndContributionReport") || this.get("isClientTaxPaymentsBetaReport") || this.get("isWageAndTaxSummaryReport") || this.get("isTaxLiabilityReport") || this.get("isLaborDistributionDetailReport")) {
					configurations.searchByRun = this.get("searchByRun");
				}

				// routing the urls for payrollRegisterReport based on location and departments
				if (this.get("isPayrollRegisterReport")) {
					if (this.get("reportDepartments").length >= 2) {
						requestUrl = requestUrl.withDepartment;
					} else {
						requestUrl = requestUrl.withoutDepartment;
					}
				}

				// for history summary report we require quarters in the payload
				if (this.get("isHistorySummaryReport")) {
					var year = configurations.startDate.substring(6);
					configurations.Q1 = ["01/01/" + year, "03/31/" + year];
					configurations.Q2 = ["04/01/" + year, "06/30/" + year];
					configurations.Q3 = ["07/01/" + year, "09/30/" + year];
					configurations.Q4 = ["10/01/" + year, "12/31/" + year];
				}

				// by default the company_location_ids should be "all" for history Summary Report
				if ((this.get("isHistorySummaryReport") || this.get("isPayrollRegisterReport") || this.get("isDeductionAndContributionReport") || this.get("isWageAndTaxSummaryReport") || this.get("isGarnishmentsAuditReport") || this.get("isGarnishmentsSetupReport") || this.get("isTaxLiabilityReport") || this.get("isReportedTips")) && typeof configurations.company_location_ids === "undefined") {
					configurations.company_location_ids = "All";
				}

				// employees should be All by default when no employees are selected
				if ((this.get("isHistorySummaryReport") || this.get("isEmployeeDetailsReportBeta") || this.get("isGarnishmentsAuditReport") || this.get("isGarnishmentsSetupReport")) && typeof configurations.employee_ids === "undefined") {
					configurations.employee_ids = "All";
				}

				_ember["default"].ajax({
					url: requestUrl,
					type: "POST",
					data: JSON.stringify(configurations),
					contentType: "application/json",
					processData: false,
					dataType: this.get("isTaxFilingReport") ? "json" : "binary"
				}).then(function (data) {
					if (that.get("isTaxFilingReport")) {
						that.send("getDownlodReportsList");
						laddaButton.stop();
						that.set("isLoading", false);
					} else if (that.get("isLiabilitiesReport")) {
						_componentLibraryComponentsNotificationModal["default"].show({
							notificationType: "success",
							title: "Liabilities Report is being generated",
							message: "You will receive an email notification as soon as the report has been created."
						});
					} else if (that.get("isNewHireFilingReport")) {
						that.send("getNewHireReportsList");
						laddaButton.stop();
						that.set("isLoading", false);
					} else {
						window.saveAs(data, fileName);
					}
					if (!that.get("isTaxFilingReport") && !that.get("isNewHireFilingReport")) {
						that.send("confirm");
					}
				})["catch"](function () {
					_componentLibraryComponentsNotificationModal["default"].show({
						notificationType: "error",
						title: "Unable to export report",
						message: "Sorry we are unable to export this report."
					});
					laddaButton.stop();
					that.set("isLoading", false);
				});
			}
		}
	});
});