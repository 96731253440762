import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { Box, Flex, FlexProps, Icon, Image, TextBlock } from 'zbase';
import { useSwitches } from 'z-frontend-network';
import { styled } from 'z-frontend-theme';
import { color } from 'z-frontend-theme/utils';
import { Link } from 'z-frontend-elements';
import { DialogManager } from 'z-frontend-overlays';

import AspectRatio from '../AspectRatio';
import TermsOfUseConfirmationModal from './TermsOfUseConfirmation';

const xmasLightsImageSrc = require('../../images/xmas-lights.svg');

const BigIcon = styled(Icon)`
  font-size: 80px;
`;

const DashedBox = styled(Box)`
  /* Use relative position so that the image inside can rely on absolute position. */
  position: relative;
  border-style: dashed;
  border-width: 2px;

  &:hover {
    background-color: ${color('tertiary.a', 0.2)};
  }
`;

const AbsoluteImage = styled(Image)`
  position: absolute;
  top: 0;

  /* Horizontally center the image */
  left: 0;
  right: 0;
  margin: auto;
`;

const marketplace_env = gql`
  query getASOMarketplaceEnvQuery {
    getASOMarketplaceEnv {
      host_url
    }
  }
`;

const AddAppTile: React.FunctionComponent<FlexProps> = props => {
  const switches = useSwitches();
  const showXmasLights = switches?.growth_gifted_xmas_push;
  const showNewMarketplace = switches?.new_marketplace_enable;
  const { data, loading } = useQuery(marketplace_env, {
    fetchPolicy: 'cache-and-network',
  });
  const hostUrl = data?.getASOMarketplaceEnv?.host_url;
  const endpoint = '/oauth2/embedded-authorize/marketplace/';
  return (
    <DialogManager
      render={dialog => {
        return (
          <Flex align="flex-start" mt={3} px={3} {...props}>
            <TermsOfUseConfirmationModal
              loading={loading}
              dialog={dialog}
              onConfirm={() => {
                window.location.href = showNewMarketplace ? `${hostUrl}${endpoint}` : '/#/integrations';
                dialog.close();
              }}
              onCancel={() => {
                dialog.close();
              }}
            />
            <Link
              onClick={() => {
                dialog.open();
              }}
              width={1}
              // This data-testid is used in https://app.pendo.io/s/5769616859332608/guides/TGuif0_d-eb2MPR4jSW_fFCfKJc
              // to set the location of the guide step "integrate with almost any app you need"
              data-testid="add-an-app"
            >
              <DashedBox height={1} width={1} p={5}>
                {showXmasLights && <AbsoluteImage src={xmasLightsImageSrc} alt="Christmas Lights" />}

                <AspectRatio ratio={1}>
                  <Flex justify="center" align="center" width={1} height={1}>
                    <BigIcon iconName="plus" />
                  </Flex>
                </AspectRatio>
              </DashedBox>
              <TextBlock textAlign="center" fontStyle="paragraphs.m" color="text.dark" width={1} height={1} mt={2}>
                {showNewMarketplace ? 'Marketplace' : 'Add an App'}
              </TextBlock>
            </Link>
          </Flex>
        );
      }}
    />
  );
};

export default AddAppTile;
