import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { useQuery } from 'react-apollo';

import { Banner, BannerFlexContainerProps } from 'z-frontend-composites';
import { Link } from 'z-frontend-elements';
import { PluralText, TextInline } from 'zbase';

import { ActiveBillingDetailsQuery } from '../gqlTypes';

type RenewalNoticeNewProps = {
  billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails;
  includeWalkMe?: boolean;
  disableRenewalBanner?: boolean;
} & BannerFlexContainerProps;

function showRenewalNotice(
  billingDetails: ActiveBillingDetailsQuery.ActiveBillingDetails,
  disableRenewalBanner: boolean,
): boolean {
  if (!billingDetails.renewalDate) {
    return false;
  }

  const { numberOfDaysBeforeRenewal } = billingDetails;
  const isRenewalSoon = numberOfDaysBeforeRenewal >= 0 && numberOfDaysBeforeRenewal <= 60;
  if (!isRenewalSoon) {
    return false;
  }

  if (disableRenewalBanner) {
    return false;
  }

  const { planDetails } = billingDetails;
  const currentPlanIsAnnual = planDetails.contractLength > 0;
  const currentPlanIsMonthly = !currentPlanIsAnnual;
  if (currentPlanIsMonthly) {
    return false;
  }

  const { isRenewingContract } = billingDetails;
  if (!isRenewingContract) {
    return false;
  }

  return true;
}

const RenewalNoticeNew: React.FunctionComponent<RenewalNoticeNewProps> = props => {
  const { billingDetails, includeWalkMe, disableRenewalBanner, ...containerProps } = props;

  const { data, loading } = useQuery(renewalCenterSummaryQuery, {
    fetchPolicy: 'cache-and-network',
  });

  if (!showRenewalNotice(billingDetails, disableRenewalBanner)) {
    return null;
  }

  if (loading || !data?.renewalCenterSummary) {
    return null;
  }

  const { mappedPlan, existingPlan } = data.renewalCenterSummary || {};

  const isPriceLifted = mappedPlan.mrr > existingPlan.mrr;
  const isRevampedPriceLifted = isPriceLifted;
  const renewalDateFormatted = moment(billingDetails.renewalDate).format('MMM DD, YYYY');

  const linkHref = isRevampedPriceLifted
    ? '/app/checkout/#/renewal-pricing'
    : `/dashboard/#/company-profile/billing-information`;

  const linkText = isRevampedPriceLifted ? 'Click for more details' : 'View Plan Info';

  const daysCount = (
    <PluralText
      count={billingDetails.numberOfDaysBeforeRenewal}
      none="today"
      one={`in {count} day on ${renewalDateFormatted}`}
      other={`in {count} days on ${renewalDateFormatted}`}
    />
  );
  const seatsCount = (
    <PluralText count={mappedPlan.newTotalSeats} none="{count} seats" one="{count} seat" other="{count} seats" />
  );

  return (
    <Banner type="info" {...containerProps}>
      <TextInline>
        {isRevampedPriceLifted ? (
          <>
            Heads up, your {billingDetails.planDetails.billingPeriod} pricing will be adjusted in your upcoming renewal{' '}
            {daysCount} with {seatsCount}.{' '}
          </>
        ) : (
          <>Your current plan will renew automatically {daysCount}. </>
        )}
        <Link href={linkHref} className={includeWalkMe ? 'js-walkme-dashboard-renewal-center-banner' : null}>
          {linkText}
        </Link>
      </TextInline>
    </Banner>
  );
};

const renewalCenterSummaryQuery = gql`
  query RenewalCenterSummary {
    renewalCenterSummary {
      isRenewingContract
      existingPlan {
        mrr
      }
      mappedPlan {
        mrr
        newTotalSeats
      }
    }
  }
`;

export default RenewalNoticeNew;
