import React, { ReactElement } from 'react';
import { DocumentNode } from 'graphql';

import { Link } from 'z-frontend-elements';
import { Box, TextInline } from 'zbase';
import { BRAND_NAME } from 'z-frontend-app-bootstrap';

import { paywallDelinquentInfoQuey } from './redirectModalQueries';
import { GetDelinquentInfo } from '../../src/gqlTypes';

export type RedirectModalConfig = {
  title: string;
  body: (params?: any, clcDisableSelfServeUpgrade?: boolean) => ReactElement;
  showFooter: boolean;
  footerButtonText?: string;
  query?: DocumentNode;
  errorBody?: ReactElement;
  redirectUrl?: string;
  redirectEmberRoute?: string;
};

type RedirectBodyType = {
  content: {
    CO?: RedirectModalConfig; // company redirect
    EE?: RedirectModalConfig;
  };
};

export const BUNDLE_EXPIRED = 'bundle-expired';

export const PREREQUISITE_REDIRECT_BODY: { [key: string]: RedirectBodyType } = {
  [BUNDLE_EXPIRED]: {
    content: {
      CO: {
        title: `Your company does not use ${BRAND_NAME}`,
        body: (params, clcDisableSelfServeUpgrade) => {
          return (
            <Box>
              <p>
                Your company is no longer with <TextInline textKey="brandName" />
                .&nbsp;
                {clcDisableSelfServeUpgrade ? (
                  <>If you need to get in touch with us,&nbsp;</>
                ) : (
                  <>
                    To take full advantage of the platform, explore&nbsp;
                    <Link target="_blank" href="/public/#/billing/choose-plan">
                      our packages
                    </Link>
                    . If you need to get in touch with us,&nbsp;
                  </>
                )}
                <Link target="_blank" href="/app/support-flow/#/final/0/email">
                  contact support.
                </Link>
              </p>
            </Box>
          );
        },
        showFooter: true,
        footerButtonText: 'Explore Packages',
        redirectUrl: '/public/#/billing/choose-plan',
      },
      EE: {
        title: `Your company no longer uses ${BRAND_NAME}`,
        body: params => (
          <Box>
            <p>
              Your company is no longer with <TextInline textKey="brandName" />. Please reach out to your HR admin.
            </p>
          </Box>
        ),
        showFooter: true,
        footerButtonText: 'Close',
      },
    },
  },
  'delinquent-customer-billing-admins': {
    content: {
      EE: {
        title: `Update your ${BRAND_NAME} Billing Information (Notice of Non-Payment)`,
        body: (params: GetDelinquentInfo.Query) => (
          <Box>
            <p>
              Our most recent attempt to debit your company for your <TextInline textKey="brandName" /> bill failed{' '}
              {params.delinquentPaymentInfo.paymentFailureReason}.
            </p>
            <p>
              If your payment method continues to fail, we may suspend or deactivate your account, refer your account to
              collections, or take other measures.
            </p>
            <p>
              If you need to get in touch with us,{' '}
              <Link target="_blank" href="/app/support-flow/#/final/0/email">
                contact support.
              </Link>
            </p>
          </Box>
        ),
        errorBody: (
          <Box>
            <p>
              Our most recent attempt to debit your company for your <TextInline textKey="brandName" /> bill failed,
              reasons can not be found. If you need to get in touch with us,{' '}
              <Link target="_blank" href="/app/support-flow/#/final/0/email">
                contact support.
              </Link>
            </p>
          </Box>
        ),
        showFooter: true,
        footerButtonText: 'Update Account',
        query: paywallDelinquentInfoQuey,
        redirectEmberRoute: 'companyProfile.billingInformation',
      },
    },
  },
  'delinquent-customer-non-billing-admins': {
    content: {
      EE: {
        title: `Update your ${BRAND_NAME} Billing Information (Notice of Non-Payment)`,
        body: (params: GetDelinquentInfo.Query) => (
          <Box>
            <p>
              There is a temporary hold on your <TextInline textKey="brandName" /> account. Please reach out to your
              main admin {params.delinquentPaymentInfo.mainAdminEmail}.
            </p>
            <p>
              If you need to get in touch with us,{' '}
              <Link target="_blank" href="/app/support-flow/#/final/0/email">
                contact support.
              </Link>
            </p>
          </Box>
        ),
        errorBody: (
          <Box>
            <p>
              Our most recent attempt to debit your company for your <TextInline textKey="brandName" /> bill failed,
              reasons can not be found. If you need to get in touch with us,{' '}
              <Link target="_blank" href="/app/support-flow/#/final/0/email">
                contact support.
              </Link>
            </p>
          </Box>
        ),
        showFooter: true,
        footerButtonText: 'OK',
        query: paywallDelinquentInfoQuey,
      },
    },
  },
};
