define('component-library/constants/z-payroll/z-payroll-earning-categories', ['exports'], function (exports) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	// This should be kept in sync with zenefits_payroll.enums.common.EarningCategory
	var EARNING_CATEGORY = {
		ADDITIONAL: 'additional',
		CO_ADDITIONAL: 'co_additional',
		ADOPTION_REIMBURSEMENT: 'adoption_reimbursement',
		ALLOWANCES: 'allowances',
		BEREAVEMENT: 'BEREAVEMENT',
		BONUS: 'bonus',
		CASH_TIPS: 'cash_tips',
		CO_DOUBLE_OVERTIME: 'co_double_overtime',
		CO_OVERTIME: 'co_overtime',
		CO_PREMIUM_DOUBLE_OVERTIME: 'co_premium_double_overtime',
		CO_PREMIUM_OVERTIME: 'co_premium_overtime',
		CO_REGULAR: 'co_regular',
		COMMISSION: 'commission',
		COVID_19_EFMLA: 'covid_19_efmla',
		COVID_19_EFMLA_ARPA: 'covid_19_efmla_arpa',
		COVID_19_FAMILY_CARE: 'covid_19_family_care',
		COVID_19_FAMILY_CARE_ARPA: 'covid_19_family_care_arpa',
		COVID_19_SELF_CARE: 'covid_19_self_care',
		COVID_19_SELF_CARE_ARPA: 'covid_19_self_care_arpa',
		DOUBLE_OVERTIME: 'double_overtime',
		EXCESS_GTL: 'imputed_income_life',
		FLOATING: 'FLOATING',
		GENERAL_IMPUTED_INCOME: 'general_imputed_income',
		HOLIDAY: 'holiday',
		JURY_DUTY: 'JURY_DUTY',
		LEAVE_OF_ABSENCE: 'LEAVE_OF_ABSENCE',
		MEAL_BREAK_EARNINGS: 'mealBreakEarnings',
		NON_CASH_TIPS: 'non_cash_tips',
		OVERTIME: 'overtime',
		OWNERS_DRAW: 'owners_draw',
		PERSONAL_LEAVE: 'PERSONAL',
		PREMIUM_DOUBLE_OVERTIME: 'premium_double_overtime',
		PREMIUM_OVERTIME: 'premium_overtime',
		PTO: 'pto',
		REGULAR: 'regular',
		REIMBURSEMENT: 'reimbursement',
		REPORTED_TIPS: 'reported_tips',
		CREDIT_CARD_REPORTED_TIPS: 'credit_card_reported_tips',
		RSU_DEFERRAL: 'rsu_deferral',
		SCORP_HEALTH_AND_DISABILITY: 'scorp_combined',
		SCORP_HEALTH_DISABILITY: 'scorp_health_disability',
		SICK_LEAVE: 'SICK',
		TPSP_AFTER_SIX_MONTHS: 'tpsp_after_six_months',
		TPSP_FIRST_SIX_MONTHS: 'tpsp_first_six_months',
		TPSP_NON_TAXABLE: 'tpsp_non_taxable',
		VACATION: 'VACATION'
	};

	exports.EARNING_CATEGORY = EARNING_CATEGORY;

	// NOTE: These should be kept private. Use EARNING_CATEGORY_TYPES if you need them.

	var COVID19_TYPES = new Set([EARNING_CATEGORY.COVID_19_EFMLA, EARNING_CATEGORY.COVID_19_FAMILY_CARE, EARNING_CATEGORY.COVID_19_SELF_CARE, EARNING_CATEGORY.COVID_19_EFMLA_ARPA, EARNING_CATEGORY.COVID_19_FAMILY_CARE_ARPA, EARNING_CATEGORY.COVID_19_SELF_CARE_ARPA]);

	// This should be kept in sync with ZPCompanyEarningType.canEditHourAndRate
	var HOURLY_TYPES = new Set([EARNING_CATEGORY.CO_DOUBLE_OVERTIME, EARNING_CATEGORY.CO_OVERTIME, EARNING_CATEGORY.CO_PREMIUM_DOUBLE_OVERTIME, EARNING_CATEGORY.CO_PREMIUM_OVERTIME, EARNING_CATEGORY.CO_REGULAR, EARNING_CATEGORY.COVID_19_EFMLA, EARNING_CATEGORY.COVID_19_FAMILY_CARE, EARNING_CATEGORY.COVID_19_SELF_CARE, EARNING_CATEGORY.DOUBLE_OVERTIME, EARNING_CATEGORY.HOLIDAY, EARNING_CATEGORY.LEAVE_OF_ABSENCE, EARNING_CATEGORY.MEAL_BREAK_EARNINGS, EARNING_CATEGORY.OVERTIME, EARNING_CATEGORY.PERSONAL_LEAVE, EARNING_CATEGORY.PREMIUM_DOUBLE_OVERTIME, EARNING_CATEGORY.PREMIUM_OVERTIME, EARNING_CATEGORY.PTO, EARNING_CATEGORY.REGULAR, EARNING_CATEGORY.SICK_LEAVE, EARNING_CATEGORY.VACATION]);

	var CO_OVERTIME_TYPES = new Set([EARNING_CATEGORY.CO_DOUBLE_OVERTIME, EARNING_CATEGORY.CO_OVERTIME, EARNING_CATEGORY.CO_PREMIUM_DOUBLE_OVERTIME, EARNING_CATEGORY.CO_PREMIUM_OVERTIME]);

	var OVERTIME_TYPES = new Set([EARNING_CATEGORY.DOUBLE_OVERTIME, EARNING_CATEGORY.OVERTIME, EARNING_CATEGORY.PREMIUM_DOUBLE_OVERTIME, EARNING_CATEGORY.PREMIUM_OVERTIME]);

	// Currently unused, commenting out for future uses.
	// const TIME_OFF_TYPES = new Set([
	// 	EARNING_CATEGORY.HOLIDAY,
	// 	EARNING_CATEGORY.PERSONAL_LEAVE,
	// 	EARNING_CATEGORY.PTO,
	// 	EARNING_CATEGORY.SICK,
	// 	EARNING_CATEGORY.VACATION,
	// ]);

	var THIRD_PARTY_SICK_PAY_TYPES = new Set([EARNING_CATEGORY.TPSP_AFTER_SIX_MONTHS, EARNING_CATEGORY.TPSP_FIRST_SIX_MONTHS, EARNING_CATEGORY.TPSP_NON_TAXABLE]);

	var EARNING_CATEGORY_TYPES = {
		COVID19_TYPES: COVID19_TYPES,
		HOURLY_TYPES: HOURLY_TYPES,
		CO_OVERTIME_TYPES: CO_OVERTIME_TYPES,
		OVERTIME_TYPES: OVERTIME_TYPES,
		ALL_OVERTIME_TYPES: new Set([].concat(_toConsumableArray(CO_OVERTIME_TYPES), _toConsumableArray(OVERTIME_TYPES))),
		// TIME_OFF_TYPES,
		THIRD_PARTY_SICK_PAY_TYPES: THIRD_PARTY_SICK_PAY_TYPES
	};
	exports.EARNING_CATEGORY_TYPES = EARNING_CATEGORY_TYPES;
});