import React, { useEffect, useState } from 'react';

import { usePermissions, PermissionChecker } from 'z-frontend-network';
import { EmptyState } from 'z-frontend-elements';
import { getSubscriptionInfo, SubscriptionInfoWithIds } from 'z-frontend-app-bootstrap';
import { useMicroTrials, useMicroTrialSubscription } from 'z-frontend-microtrials';

import AppTile, { AppTileSkeleton } from './AppTile';
import AddAppTile from './AddAppTile';
import AppListWrapper from './AppListWrapper';
import { DashboardRole } from '../../types';
import { getAppTiles, getVisibleAppTiles } from './app-utils';
import MicroTrialsPromoAppTiles from '../micro-trials/MicroTrialsPromoAppTiles';
import { AppsQuery } from '../../gqlTypes';

import { CONTRACTOR_PAYMENTS, IOM } from '../../constants';

export const tileWidths = [1 / 3, 1 / 4, 1 / 5, 1 / 5, 1 / 5];

const appTileProps = {
  width: tileWidths,
  className: 'js-walkme-dashboard-addAnApp',
};

type AddAppTileContainerProps = {
  role: DashboardRole;
  readOnly: boolean;
  isIOM: boolean;
  isContractorPaymentsCompany: boolean;
};
const AddAppTileContainer = (props: AddAppTileContainerProps) => {
  const isAddRemoveAvailable = props.role === 'ADM' && !props.readOnly && !props.isContractorPaymentsCompany;
  const isIOMOnly = props.isIOM && !props.isContractorPaymentsCompany;
  return (
    !isIOMOnly &&
    isAddRemoveAvailable && (
      <PermissionChecker permission="platform:add_remove_apps">
        <AddAppTile {...appTileProps} />
      </PermissionChecker>
    )
  );
};

type AppListProps = {
  role?: DashboardRole;
  data: AppsQuery.Query;
  loading?: boolean;
  isIOM: boolean;
};
const AppList: React.FC<AppListProps> = props => {
  const { role, data, loading } = props;
  const {
    loading: microTrialSubscriptionLoading,
    hasUpgraded,
    isMicroTrialFeatureActive,
    isMicroTrialFeatureExpired,
  } = useMicroTrialSubscription();
  const { permissions } = usePermissions();
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionInfoWithIds>(null);
  const [isSubscriptionDataLoading, setIsSubscriptionDataLoading] = useState<boolean>(true);
  const { microTrial } = useMicroTrials();

  const isAdmin = data?.dashboard?.permission?.isAdmin;
  const payrollCompanyHasContractors = data?.dashboard?.company?.payrollCompanyHasContractors;

  useEffect(() => {
    const getData = async () => {
      const data = await getSubscriptionInfo({ isAdmin });
      setSubscriptionData(data);
      setIsSubscriptionDataLoading(false);
    };

    getData();
  }, [isAdmin]);

  if (loading || !role || isSubscriptionDataLoading || microTrialSubscriptionLoading) {
    return (
      <AppListWrapper>
        {Array.from(Array(10).keys()).map(integer => (
          <AppTileSkeleton width={tileWidths} key={integer.toString()} />
        ))}
      </AppListWrapper>
    );
  }

  const { zAppInstallSubscriptions, switches, isSpoofing, isTrialCompany } = data.dashboard;
  const prospectAccountType = data.dashboard.company?.prospectAccount?.type || '';
  const availableAppTiles = getAppTiles(zAppInstallSubscriptions, switches, isSpoofing, prospectAccountType);
  const isContractorPaymentsCompany = data?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);
  const isCpWithIomCompany = isContractorPaymentsCompany && data?.dashboard?.features?.includes(IOM);
  const employmentType = data?.dashboard?.employee?.employmentType;

  const visibleAppTiles = getVisibleAppTiles(
    availableAppTiles,
    role,
    switches,
    permissions,
    subscriptionData,
    !!prospectAccountType,
    isContractorPaymentsCompany,
    isCpWithIomCompany,
    employmentType,
    payrollCompanyHasContractors,
  );

  const { readOnlyAccess } = data;
  const readOnlySku = readOnlyAccess ? !readOnlyAccess.canEdit : false;

  const performanceManagementAppTile = visibleAppTiles.find(
    appTile =>
      appTile.uniqueId === '1.com.zenefits.TalentAdmin' || appTile.uniqueId === '1.com.zenefits.TalentEmployee',
  );

  const isMicrotrialFeatureActive = isMicroTrialFeatureActive('talent');
  const isMicrotrialExpiredNotUpgraded = isMicroTrialFeatureExpired('talent') && !hasUpgraded('talent');
  const showPerformanceManagementAppTiles =
    performanceManagementAppTile && (isMicrotrialFeatureActive || isMicrotrialExpiredNotUpgraded);
  let microTrialPromoTileProps = null;

  if (showPerformanceManagementAppTiles) {
    microTrialPromoTileProps = {
      microTrialFeatures: ['talent'],
      hasTrialStarted: true,
    };
  } else if (microTrial) {
    microTrialPromoTileProps = {
      microTrialFeatures: microTrial.features,
      microTrialId: microTrial.id,
      microTrialKey: microTrial.key,
      duration: microTrial.durationInDays,
      hasTrialStarted: false,
    };
  }

  return (
    <>
      {microTrialPromoTileProps && <MicroTrialsPromoAppTiles {...microTrialPromoTileProps} />}
      <AppListWrapper>
        {!visibleAppTiles.length && <EmptyState iconName="block" message="No apps available." />}
        {visibleAppTiles.map(card => (
          <AppTile width={tileWidths} key={card.uniqueId} hideUpgradeBadge={isTrialCompany} {...card} />
        ))}
        <AddAppTileContainer
          role={role}
          readOnly={readOnlySku}
          isIOM={props.isIOM}
          isContractorPaymentsCompany={isContractorPaymentsCompany}
        />
      </AppListWrapper>
    </>
  );
};

export default AppList;
