export const BRAND_NAME = 'TriNet';

export const CONTRACTOR_PAYMENTS = 'contractorPayments';
export const IOM = 'iom';

export const SWITCHES = {
  niceBot: 'clc_use_nice_bot',
  optOutFreeTrialPayrollDiscount: 'clc_ooft_payroll_discount',
  disableSelfServeUpgrade: 'clc_disable_self_serve_upgrade',
};

// Used for a temporary fix for NICE loader script error
// Original error message example: Timeout for request '81ec5923-3318-4290-8e4d-5013463207e7'
// in which the ID is changed every hour or so.
// Therefore it creates a new Sentry alert and PagerDuty incident every couple hours.
// The error was thrown by the NICE loader script (https://web-modules-de-na1.niceincontact.com/loader/1/loader.js)
// which is included in boot-nice.ts.
// Example Sentry error:
// https://trinet-zenefits.sentry.io/issues/6045601863/?project=6107451&query=%22Timeout%20for%20request%22&referrer=issue-stream&sort=date&statsPeriod=90d&stream_index=0
export const NICE_LOADER_SCRIPT_ERROR = 'Timeout for request';
export const NICE_LOADER_SCRIPT_ERROR_CONVERTED = 'Timeout for request - NICE loader script error';
