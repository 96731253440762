define('component-library/components/payroll/pay-types/add-earning-type-modal', ['exports', 'ember', 'component-library/components/notification-modal', 'component-library/constants/z-payroll/z-payroll-earning-categories'], function (exports, _ember, _componentLibraryComponentsNotificationModal, _componentLibraryConstantsZPayrollZPayrollEarningCategories) {
	'use strict';

	exports['default'] = _ember['default'].Component.extend({
		templateName: 'components/payroll/pay-types/add-earning-type-modal',
		autoSetHeight: false,
		zpCompany: null,
		selectedAccountingOrganization: null,
		isGenerated: false,
		eventLogger: _ember['default'].inject.service(),
		payTypesService: _ember['default'].inject.service('payroll/pay-types-service'),
		disableCategoryModification: _ember['default'].computed.or('model.id', 'isGenerated'),
		disabled: _ember['default'].computed.or('shouldLock', 'isGenerated'),
		earningCategory: _ember['default'].computed.alias('model.category'),
		isNewEarning: _ember['default'].computed.alias('model.isNew'),
		disabledText: 'This earning is managed by Zenefits and cannot be edited.',

		init: function init() {
			this._super();
			var model = this.get('model');
			if (!model) {
				this.set('model', window.App.ZPayrollCompanyEarningType.createRecord({
					zpCompany: this.get('zpCompany'),
					category: this.get('earningCategories.firstObject.value'),
					regularEarningMultiplier: 1,
					unitName: 'Hours',
					isGenerated: false,
					isActive: true
				}));
			} else {
				var _name = model.get('name');
				var category = model.get('category');
				var isVariableComp = [_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.BONUS, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.COMMISSION, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.OWNERS_DRAW].includes(category) && _name && _name.includes('Variable Compensation - ');
				if (isVariableComp && this.get('disabled')) {
					this.set('disabledText', 'To edit this earning type, go to settings in Hiring. If you do not have access to Hiring, contact your company HR.');
				}
			}
		},

		earningCategories: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		earningTypes: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		accountingOrganizations: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		accountingAccounts: _ember['default'].computed(function () {
			return _ember['default'].A();
		}),

		validAccountingAccounts: _ember['default'].computed('accountingAccounts.[]', 'selectedAccountingOrganization', function () {
			var accountingOrganization = this.get('selectedAccountingOrganization');
			return this.get('accountingAccounts').filterBy('accountingOrganization', accountingOrganization);
		}),

		selectedExpenseAccounts: (function (key, value) {
			var accountingOrganization = this.get('selectedAccountingOrganization');
			if (arguments.length === 1) {
				return this.get('model.accountingAccounts').findBy('accountingOrganization', accountingOrganization);
			} else if (value) {
				var accounts = this.get('model.accountingAccounts');
				var accountsToRemove = accounts.filterBy('accountingOrganization', accountingOrganization);
				accounts.removeObjects(accountsToRemove);
				accounts.pushObject(value);
				return value;
			}
			return value;
		}).property('model.accountingAccounts.[]', 'selectedAccountingOrganization'),

		isCategoryOvertime: _ember['default'].computed('earningCategory', function () {
			return _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY_TYPES.OVERTIME_TYPES.has(this.get('earningCategory'));
		}),

		isCategoryRatePerUnit: _ember['default'].computed('earningCategory', function () {
			return _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY_TYPES.HOURLY_TYPES.has(this.get('earningCategory'));
		}),

		showIsRatePerUnit: _ember['default'].computed('earningCategory', function () {
			return [_componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.ADDITIONAL, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.CO_ADDITIONAL, _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.ALLOWANCES].includes(this.get('earningCategory'));
		}),

		hasDuplicate: function hasDuplicate(existingObjects, modifiedObject) {
			return existingObjects.any(function (object) {
				return object.get('id') != modifiedObject.get('id') && object.get('name').trim() == modifiedObject.get('name').trim() && object.get('isActive');
			});
		},

		actions: {
			saveEarning: function saveEarning() {
				var _this = this;

				var model = this.get('model');

				if (!model.get('name')) {
					return _componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Missing name',
						message: 'Please specify a name.'
					});
				}

				if (this.hasDuplicate(this.get('earningTypes'), model)) {
					return _componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Duplicate earnings name',
						message: 'An earning with this name already exists. Choose a different name and try again.'
					});
				}

				// For OT/ DOT - set isRatePerUnit to True if isMultipleOfRegularEarning is False
				// This code placed here as we have radio button to display isMultipleOfRegularEarning options
				// So OT and DOT it can take either true or false, thus if false then we need to set rate per unit to true
				if (!model.get('isMultipleOfRegularEarning') && this.get('isCategoryRatePerUnit')) {
					model.set('isRatePerUnit', true);
				}

				return model.save().then(function () {
					_this.get('earningTypes').reload();
				}, function (error) {
					return _componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Internal error',
						message: JSON.parse(error.responseText).error_message
					});
				})['finally'](function () {
					_this.attrs.closeModal();
				});
			},

			cancelEarning: function cancelEarning() {
				if (this.get('isNewEarning')) {
					this.get('model').deleteRecord();
				}
				this.attrs.closeModal();
			},

			deleteEarning: function deleteEarning(deleteButton) {
				var _this2 = this;

				var payType = this.get('model');
				var payTypeId = payType.get('id');
				var payTypesService = this.get('payTypesService');
				var companyId = this.get('zpCompany') ? this.get('zpCompany').get('company.id') : null;

				payTypesService.deleteEarningPayType(payTypeId, companyId).then(function () {
					payType.set('isActive', null); //Delete in front-end
				})['catch'](function (error) {
					_this2.get('eventLogger').log('delete_earning_pay_type_error', {
						error: error.responseText,
						payTypeId: payTypeId
					});

					return _componentLibraryComponentsNotificationModal['default'].show({
						notificationType: 'error',
						title: 'Internal error',
						message: 'Oops! Something went wrong. We are working on getting this fixed as soon as we can. Please try again.'
					});
				})['finally'](function () {
					deleteButton.stop();
					_this2.get('earningTypes').reload();
					_this2.attrs.closeModal();
				});
			},

			earningTypeCategoryChange: function earningTypeCategoryChange() {
				var payType = this.get('model');
				// TODO: Bob Z add more to this hook for the pay types refatcor project
				if (!this.get('isCategoryAllowanceOrAdditional')) {
					payType.set('isRatePerUnit', false);
					payType.set('unitName', 'Hours');
				}
				if (!this.get('isCategoryOvertime')) {
					payType.set('isMultipleOfRegularEarning', false);
				}
				if (this.get('model.category') === _componentLibraryConstantsZPayrollZPayrollEarningCategories.EARNING_CATEGORY.LEAVE_OF_ABSENCE) {
					payType.set('isRatePerUnit', true);
				}
			}
		}
	});
});