import gql from 'graphql-tag';
import { UAParser } from 'ua-parser-js';

import { getApollo } from './getApollo';
import { BootNiceQuery } from './gqlTypes';
import { EmployeeType } from '../schema/schemaTypes';

declare global {
  interface Window {
    cxone: any; // Define this so we can use window.cxone below without a type error
  }
}

type BootNiceParams = {
  isAdmin: boolean;
  isContractorPayments: boolean;
  isInImplementation: boolean;
};

// Values for Employee Type field ("Employee_Type__c") in Salesforce case objects
export enum SfdcEmployeeType {
  Admin = 'Admin',
  Employee = 'Employee',
  ContractorEmployee = 'Employee (contractor)',
}

const bootNiceQuery = gql`
  query BootNiceQuery {
    dashboard(id: "me") {
      id
      company {
        id
        hrProxy {
          id
          hrContact {
            id
            hrContactName
            hrContactPhone
            hrContactEmail
          }
        }
      }
      employee {
        id
        preferredOrFirstName
        last_name
        type
      }
      niceCxoneExpertAuthtoken
    }
    roleAndCategory {
      isBroker
    }
  }
`;

const getGqlData = async () => {
  const { data } = await getApollo().query<BootNiceQuery.Query>({
    query: bootNiceQuery,
    context: { headers: { 'IS-BACKGROUND-QUERY': true } },
  });

  return data;
};

export const getMenuType = (params: BootNiceParams & { isBroker?: boolean | null }) => {
  const { isAdmin, isBroker, isContractorPayments, isInImplementation } = params;

  const role = isBroker ? 'Broker' : isAdmin ? 'Admin' : 'Employee';

  return `${isContractorPayments ? 'contractorPayments' : 'regular'}${
    isInImplementation ? 'Implementation' : 'Active'
  }${role}`;
};

export enum CustomerProfile {
  Regular = 'regular',
  ContractorPaymentsAdmin = 'contractorPayments_admin',
  ContractorPaymentsEmployee = 'contractorPayments_employee',
}

export const getCustomerProfile = (params: { isAdmin: boolean; isContractorPayments: boolean }) => {
  const { isAdmin, isContractorPayments } = params;

  if (!isContractorPayments) {
    return CustomerProfile.Regular;
  }

  // It is Contractor Payments which means the user cannot be a broker, so we only need to consider admin or employee.
  return isAdmin ? CustomerProfile.ContractorPaymentsAdmin : CustomerProfile.ContractorPaymentsEmployee;
};

export const getSfdcEmployeeType = (
  isBroker: boolean | null | undefined,
  isAdmin: boolean,
  employeeType: EmployeeType | null | undefined,
) => {
  const isNonBrokerAdmin = !isBroker && isAdmin;

  if (isNonBrokerAdmin) {
    return SfdcEmployeeType.Admin;
  }

  if (employeeType === EmployeeType.HC) {
    return SfdcEmployeeType.ContractorEmployee;
  }

  return SfdcEmployeeType.Employee;
};

/**
 * This function gets the user's browser and OS information.
 * The logic is based on function formattedUserAgent in yourPeople3.
 */
export const getUserBrowser = (userAgent: string) => {
  const parser = new UAParser(userAgent);
  const browser = parser.getBrowser();
  const os = parser.getOS();

  return `BROWSER: ${browser.name} ${browser.version}, OS: ${os.name} ${os.version}`;
};

export const toggleNiceWidget = () => {
  const widgetButton =
    document.querySelector<HTMLElement>('[data-selector="GUIDE_MENU_BUTTON"]') ||
    document.querySelector<HTMLElement>('[data-selector="GUIDE_CUSTOMER_PORTAL_CLOSE_BUTTON"]');
  widgetButton?.click();
};

export default async (params: BootNiceParams) => {
  if (window.cxone) {
    // NICE script is already loaded.
    return;
  }

  const {
    dashboard: { company, employee, niceCxoneExpertAuthtoken },
    roleAndCategory,
  } = await getGqlData();

  const isBroker = roleAndCategory?.isBroker;
  const menuType = getMenuType({ ...params, isBroker });

  const { hrContactName: rawHrContactName, hrContactPhone, hrContactEmail } = company?.hrProxy?.hrContact || {};
  // Escape single quotes in HR contact name. Otherwise it might break the script below.
  const hrContactName = rawHrContactName?.replace(/'/g, "\\'");
  // employeeId is sent to the bot so that the bot can use it to get the contact id in Salesforce
  const { id: employeeId, preferredOrFirstName, last_name, type: employeeType } = employee || {};
  // Escape single quotes in user's full name. Otherwise it might break the script below.
  const userFullName = `${preferredOrFirstName} ${last_name}`.replace(/'/g, "\\'");
  const { isAdmin, isContractorPayments } = params;
  const customerProfile = getCustomerProfile({
    isAdmin,
    isContractorPayments,
  });
  // This value is sent to the bot so that the bot can set the employee type field when creating chat cases in SFDC
  // For email cases, see 'Employee_Type__c' in function assembleSalesforceCaseObject in yourPeople3
  const sfdcEmployeeType = getSfdcEmployeeType(isBroker, isAdmin, employeeType);
  // This value is sent to the bot so that the bot can set the corresponding field in chat cases in SFDC
  // For email cases, see 'User_browser__c' in function assembleSalesforceCaseObject in yourPeople3
  const userBrowser = getUserBrowser(navigator.userAgent);

  // If authtoken is not available, skip setting it in the script so that the bot icon can still show up.
  const setExpertAuthtoken = niceCxoneExpertAuthtoken
    ? `cxone('chat','setCaseCustomField', 'expertauthtoken', '${niceCxoneExpertAuthtoken}');`
    : '';

  const scriptCode = `
    (function(n,u){
        window.CXoneDfo=n,
        window[n]=window[n]||function(){(window[n].q=window[n].q||[]).push(arguments)},window[n].u=u,
        e=document.createElement("script"),e.type="module",e.src=u+"?"+Math.round(Date.now()/1e3/3600),
        document.head.appendChild(e)
        })('cxone','https://web-modules-de-na1.niceincontact.com/loader/1/loader.js');

        // Tenant ID
        cxone('init', '4419');
        cxone('guide', 'init');

    function setCustomFields() {
      cxone('chat','setCaseCustomField', 'menutype', '${menuType}');
      /*
        Why using field 'hrcontactname' instead of 'companyhrcontactname' (to be consistent with others)?
        - We used to have a field companyhrcontactname, but that field got messed up in NICE,
          so we decided to switch to the new field hrcontactname instead of waiting for the old field to be fixed
          on the NICE side.

        Why not sending empty string for custom fields?
        - The cxone API does not allow empty string to be set as custom field value.
        - Also tried skipping a line if the corresponding value is not available. That does not work either because it
          shows the raw field name directly in the UI. For example, "{contact.custom_fields.companyhrcontactphone}"
          will be showed.
      */
      cxone('chat','setCaseCustomField', 'hrcontactname', '${hrContactName || 'Name: not provided'}');
      cxone('chat','setCaseCustomField', 'companyhrcontactphone', '${hrContactPhone || 'Phone: not provided'}');
      cxone('chat','setCaseCustomField', 'companyhrcontactemail', '${hrContactEmail || 'Email: not provided'}');
      cxone('chat','setCaseCustomField', 'userfullname', '${userFullName}');
      ${setExpertAuthtoken}
      cxone('chat','setCaseCustomField', 'employeeid', '${employeeId}');
      cxone('chat','setCaseCustomField', 'employeetype', '${sfdcEmployeeType}');
      cxone('chat','setCaseCustomField', 'userbrowser', '${userBrowser}');
    }

    //set the custom fields on page refresh and initial load
    setCustomFields();

        // Let the bot start the conversation
        cxone('chat','sendFirstMessageAutomatically', '/begin_conversation');

        // Skip asking user to enter their name.
        cxone('chat', 'autoStartSession');
        cxone('chat','setCustomerName', '${userFullName}');

    // When a chat ends, and user starts a new chat, this code will refresh the bot, which refills needed variables.
    // Without this, bot cannot properly recognize the customer.
    cxone('chat', 'onPushUpdate', ['MessageCreated', 'CaseStatusChanged'], (evt) => {
      if (evt.data.case.status == 'closed') {
        setCustomFields();
        setTimeout(() => {}, 2000);
      }
    });

        // This will trigger the different Guide templates where the different knowledge configurations are setup.
        cxone('analytics','setVisitorVariable', 'customerprofile', '${customerProfile}');
    `;

  const script = document.createElement('script');
  script.innerHTML = scriptCode;
  document.head.appendChild(script);
};
